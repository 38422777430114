/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';
import { Form, Tab, Tabs} from 'react-bootstrap';
import {Col, FormGroup} from "reactstrap";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {browserHistory} from "react-router";
import * as w17Actions from "../../../actions/w17-actions";
import * as w18Actions from "../../../actions/w18-actions";
import * as W17F1026Actions from "../../../actions/w17F1026-actions";
import * as userActions from "../../../actions/user-actions";

import Attachment from "../../common/attachment/attachment";
import {Column} from 'devextreme-react/data-grid';
import EventTracking from "../../common/tracking/event";
import GridContainer from "../../common/grid-container/grid-container";
import GridActionBar from "../../common/action-bar/grid-action-bar";
import {Loading} from "../../common/loading/loading";
import Detail from "../../W18/W18F1013";
import ActionTab from "../../W18/W18F1014";
import PopupView from "../../popup/popup-view";
import Config from "../../../config";
import notify from 'devextreme/ui/notify';
import SendMail from "../../W17/W17F1002";
import W18F1018 from "../../W18/W18F1018"
import W18F1017 from "../../W18/W18F1017"
import W17F1026Price from "./W17F1026_Price"
import W17F1026Request from "./W17F1026_Request"
import W17F1026Order from "./W17F1026_Order"
import W17F1026Master from "./W17F1026_Master"
import PopupScroll from "../../popup/popup-scroll";

import moment from "moment";
import 'moment/locale/vi'
import W17F1026Case from "./W17F1026_Case";
import W17F1026Delivery from "./W17F1026_Delivery";

moment.locale('vi');
const labelConstants ={
    Customer:{
        CompanyName: "CRM_Ten_khach_hang",
        CompanyID: "CRM_Ma_khach_hang",
        FormTitle:"CRM_Khach_hang",
    },
    Competitor:{
        CompanyName: "CRM_Ten_doi_thu",
        CompanyID: "CRM_Ma_doi_thu",
        FormTitle:"CRM_Doi_thu",
    },
    default:{
        CompanyName: "CRM_Ten_doi_tac",
        CompanyID: "CRM_Ma_doi_tac",
        FormTitle:"CRM_Doi_tac",
    }
};

const permissionName = new Map([
    ['D17F1010', 'iPermission'],
    ['D17F2110', 'iPermissionActivity'],
    ['D17F1027', 'iPermissionDelivery'],
    ['D17F1125', 'iPermissionContact'],
    ['D17F1126', 'iPermissionDoc'],
    ['D17F1018', 'iPermissionW18F1018'],
    ['D17F1017', 'iPermissionW18F1017'],
    ['D05F1611', 'iPermissionPrice'],
    ['D05F1621', 'iPermissionOrder'],
    ['D17F1861', 'iPermissionCase'],
    ['D17F1011', 'iPermissionConvert'],
    ['W17F2025', 'iPermissionW17F2025'],
]);
class W17F1026Page extends React.PureComponent {
    constructor(props) {
        super(props);
        if (!this.getInfo()) return;
        this.filter = {
            skip: 0,
            limit: 20,
            search: ''
        };
        this.totalRecordsActionTab = 0;
        this.dataW18F1014 = {
            mode: '',
            rowData: '',
        };
        this.state = {
            isSaved:false,
            showPopup: false,
            showAttachment: false,
            showW18F1014: false,
            showSendMail: false,
            disableConvertBtn:false,
            iPermission: 0,
            iPermissionDoc: 0,
            iPermissionActivity: 0,
            iPermissionDelivery: 0,
            iPermissionContact: 0,
            iPermissionW18F1018: 0,
            iPermissionW18F1017: 0,
            iPermissionPrice: 0,
            iPermissionOrder: 0,
            iPermissionCase: 0,
            iPermissionConvert: 0,
            key: 1,

            columns: [
                {name: 'CustomerGroupName', title: Config.lang("CRM_Ten_nhom_khach_hang")},
                {name: 'Description', title: Config.lang("CRM_Ten_nhom_khach_hang")},
                {name: 'Disabled', title: Config.lang("CRM_Ngung_hoat_dong")},
            ],
            rows: [],
            callAdd: false,
            row: {
                id: '',
                FullNameU: '',
                JobPositionU: '',
                Birthday: '',
                Gender: '',
                ContactStatus: '',
                NoteU: '',
                MobileNo: '',
                OfficeTelNo: '',
                HomeAddress: '',
                Email: '',
                Email2: '',
                IsContactAdmin: 0,
                Skype: '',
                IsAdmin: false,
                mode: 'add',
            },
            rowDoc: {
                attachmentID: '',
                tableName: '',
                notes: '',
                description: '',
                fileName: '',
                fileSize: 0,
                url: '',
                createDate: '',
            },
            collapse1: true,  // thong tin chung
            collapse2: false, // thong tin bo sung
            collapse3: false,// dia chi
            loading: false,
            selectedTab: 1,
            attachmentID: '',
            masterInputData:{
                CompanyID: "",
                CompanyTypeID: "TN",
                IsPerson: 0,
                CompanyShortU: '',
                CompanyNameU: '',
                CaseSourceID: '',
                IndustryGroupID: '',
                CustomerLevelID: '',
                GroupSalesID: '',
                SalesPersonID: '',
                SalesPersonName: '',
                NotesU: '',

                //nlh
                ContactID:"",
                FullName: '',
                MobileNo_NLH : '',
                Gender_NLH: "M",
                Email2_NLH : '',
                IsContactAdmin:0,
                //legal
                LegalPersonU: '',
                LTitleU: '',
                LBirthday: '',
                LIDCardNo: '',
                LIDCardIssueDate: '',
                LIDCardIssuePlaceU: '',
                LMobileNo: '',
                LEmail: '',
                LAddressLineU: '',
                LContactAddressU: '',

                //person
                Telephone: '',
                Email: '',
                DateOfBirth: '',
                Gender: 'M',
                IDCardNo: '',
                IDCardIssueDate: '',
                IDCardIssuePlaceU: '',
                Passport: '',
                PassportValidFrom: '',
                AddressLineU: '',
                CountryID: 'VIE',
                CountryNameU: '',
                ProvinceID: '',
                ProvinceNameU: '',
                DistrictID: '',
                DistrictNameU: '',
                WardID: '',
                WardName: '',
                ContactAddressU: '',
                ContactCountryID: 'VIE',
                ContactCountryNameU: '',
                ContactProvinceID: '',
                ContactProvinceNameU: '',
                ContactDistrictID: '',
                ContactDistrictNameU: '',
                ContactWardID: '',
                ContactWardNameU: '',

                //Org
                Telephone_Org: '',
                Email_Org: '',
                TaxNo: '',
                LicenseNo: '',
                CompanyFaxNo: '',
                CompanyGroupID: '',
                RegisteredAddressU: '',
                RegisteredCountryID: 'VIE',
                RegisteredCountryNameU: '',
                RegisteredProvinceID: '',
                RegisteredProvinceNameU: '',
                RegisteredDistrictID: '',
                RegisteredDistrictNameU: '',
                RegisteredWardID: '',
                RegisteredWardNameU: '',
                CompanyContactAddressU: '',
                CompanyContactCountryID: 'VIE',
                CompanyContactCountryNameU: '',
                CompanyContactProvinceID: '',
                CompanyContactProvinceNameU: '',
                CompanyContactDistrictID: '',
                CompanyContactDistrictNameU: '',
                CompanyContactWardID: '',
                CompanyContactWardNameU: '',
                CompanyID_Edit: ''
            }
        };
        this.timeDeplay = Config.getSetting("TIME_SUBMIT_DELAY") || 2000;
        this.filterW18F1013 = {
            skip: 0,
            limit: 20,
            search: ''
        };
        const {permission} = this.getInfo();
        this.isPermission = permission;
        this.firstTime = true;
        this.w17F1026MasterRef = null;
    };

    getInfo = (flag) => {
        const {location} = this.props
        const url = new window.URLSearchParams(window.location.search);
        let state =  {}
        const keys = [...url.keys()]
        if (keys.length) {

            for (let key of keys) {
               state[key] = url.get(key)
            }
        }
        state = {
            ...state,
            ...( (location && location.state) || {})
        }
        state.permission = state.permission || parseInt(state.Permission || 0)
        state.companyID = state.CompanyID
        if ( (!state.type)) {
             browserHistory.push(Config.env.url + '/W17F1025');
             return false
        }
        return state

    };

    async loadPermission() {
        const arrPerName = Object.keys(Object.fromEntries(permissionName));
        await this.props.userActions.getPermission(Config.profile.UserID, arrPerName, (arrPer) => {
            if (arrPer !== 0) {
                const objPer = arrPer.reduce((valObj, next) => {
                    const name = permissionName.get(next.ScreenID);
                    if (next?.Permission && name) valObj[name] = next.Permission;
                    return valObj;
                }, {});
                this.setState(objPer);
            }
        });
    }

    componentDidMount = async () => {
        const {mode, currentTabKey} = this.getInfo();
        await this.loadPermission();
        if (this.state && this.state.iPermission <= 0) return;
        if (!this.getInfo()) return;

        if (mode === "add") {
            this.props.dispatch(w18Actions.loadActionTabW18F1012Complete([]));
            this.props.dispatch(w18Actions.loadW18F1013Complete([]));
            this.props.dispatch(w17Actions.loadW17F0001Complete(null));
        }
        if (mode === "edit") {
            this.loadFormMaster()
            this.loadDataW13();
            this.loadDataTabAction();
        }
        if (mode === "view") {
            this.loadFormMaster()
            this.loadDataW13();
            this.loadDataTabAction();
        }
        if(currentTabKey) this.setState({ key: currentTabKey }); // Quay về và focus vào Tab hiện tại
        this.getCustomerSettingValue();

    };

    getCustomerSettingValue = () =>{
        this.props.W17F1026Actions.getCustomerSettingValue((error)=> {
            if (error) {
                const message = error?.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        })
    };
    
    getBaseParams(){
        const {CompanyID,CompanyTypeID} = this.getInfo();
        return {
            UserID: Config.profile.UserID || '',
            Language: Config.language || '84',
            FormID: 'W17F1026',
            CompanyID,
            CompanyTypeID,
        };
    }
    /**
     * @Load..
     */
    loadFormMaster(cb) {
        const paramMaster = {
            ...this.getBaseParams(),
        };
        this.setState({loading: true});
        this.props.W17F1026Actions.getFormMaster(paramMaster, (error, data) => {
            this.setState({loading: false});
            if (error || data.length === 0) {
                let message = error ? error.message : Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({isSubmit: false, message: message});
                return false;
            }
            this.w17F1026MasterRef.loadSalesPerson(data?.GroupSalesID || ""); // Load Combo NVKD theo GroupSalesID
            let {
                IsPerson = 0,
                CompanyID,
                CompanyTypeID,
                CompanyShort: CompanyShortU = '',
                CompanyName: CompanyNameU = '',
                CaseSourceID = '',
                IndustryGroupID = '',
                CustomerLevelID = '',
                GroupSalesID = '',
                SalesPersonID = '',
                SalesPersonName = '',
                Notes: NotesU = '',

                //nlh
                ContactID  = "",
                FullName= '',
                MobileNo_NLH= '',
                Gender_NLH = "M",
                Email2_NLH = '',
                IsContactAdmin=0,
                //legal
                LegalPerson: LegalPersonU = '',
                LTitle: LTitleU = '',
                LBirthday = '',
                LIDCardNo = '',
                LIDCardIssueDate = '',
                LIDCardIssuePlace: LIDCardIssuePlaceU = '',
                LMobileNo = '',
                LEmail = '',
                LAddressLine:LAddressLineU = '',
                LContactAddress: LContactAddressU = '',

                //person
                Telephone = '',
                Email = '',
                DateOfBirth = '',
                Gender = 'M',
                IDCardNo = '',
                IDCardIssueDate = '',
                IDCardIssuePlace: IDCardIssuePlaceU = '',
                Passport = '',
                PassportValidFrom = '',
                AddressLine = '',
                CountryID = '',
                CountryName: CountryNameU = '',
                ProvinceID = '',
                ProvinceName: ProvinceNameU = '',
                DistrictID = '',
                DistrictName: DistrictNameU = '',
                WardID = '',
                WardName = '',
                ContactAddress: ContactAddressU = '',
                ContactCountryID = '',
                ContactCountryName: ContactCountryNameU = '',
                ContactProvinceID = '',
                ContactProvinceName: ContactProvinceNameU = '',
                ContactDistrictID = '',
                ContactDistrictName: ContactDistrictNameU = '',
                ContactWardID = '',
                ContactWardName: ContactWardNameU = '',

                //Org
                Telephone_Org = '',
                Email_Org = '',
                TaxNo = '',
                LicenseNo = '',
                CompanyFaxNo = '',
                CompanyGroupID = '',
                RegisteredAddress: RegisteredAddressU = '',
                RegisteredCountryID = '',
                RegisteredCountryName: RegisteredCountryNameU = '',
                RegisteredProvinceID = '',
                RegisteredProvinceName: RegisteredProvinceNameU = '',
                RegisteredDistrictID = '',
                RegisteredDistrictName:RegisteredDistrictNameU = '',
                RegisteredWardID = '',
                RegisteredWardName:RegisteredWardNameU = '',
                CompanyContactAddress:CompanyContactAddressU = '',
                CompanyContactCountryID = '',
                CompanyContactCountryName:CompanyContactCountryNameU = '',
                CompanyContactProvinceID = '',
                CompanyContactProvinceName:CompanyContactProvinceNameU = '',
                CompanyContactDistrictID = '',
                CompanyContactDistrictName:CompanyContactDistrictNameU = '',
                CompanyContactWardID = '',
                CompanyContactWardName:CompanyContactWardNameU = '',
                ObjectID = '',
                DivisionID = '',
                PaymentTermID = '',
                Approved = 0,
                CompanyID_Edit = ''
            } =data || {}
            Gender_NLH = Gender_NLH || "M"
            DateOfBirth = DateOfBirth ? moment(DateOfBirth).format(moment.HTML5_FMT.DATE): ""
            LBirthday = LBirthday ? moment(LBirthday).format(moment.HTML5_FMT.DATE): ""
            LIDCardIssueDate = LIDCardIssueDate ? moment(LIDCardIssueDate).format(moment.HTML5_FMT.DATE): ""
            IDCardIssueDate = IDCardIssueDate ? moment(IDCardIssueDate).format(moment.HTML5_FMT.DATE): ""
            PassportValidFrom = PassportValidFrom ? moment(PassportValidFrom).format(moment.HTML5_FMT.DATE): ""
            const masterInputData = {
                CompanyID,
                CompanyTypeID,
                IsPerson,
                CompanyShortU,
                CompanyNameU,
                CaseSourceID,
                IndustryGroupID,
                CustomerLevelID,
                GroupSalesID,
                SalesPersonID,
                SalesPersonName,
                NotesU,


                //nlh
                ContactID,
                FullName,
                MobileNo_NLH ,
                Gender_NLH,
                Email2_NLH ,
                IsContactAdmin,
                //legal
                LegalPersonU,
                LTitleU,
                LBirthday,
                LIDCardNo,
                LIDCardIssueDate,
                LIDCardIssuePlaceU,
                LMobileNo,
                LEmail,
                LAddressLineU,
                LContactAddressU,

                //person
                Telephone,
                Email,
                DateOfBirth,
                Gender: Gender || "M",
                IDCardNo,
                IDCardIssueDate,
                IDCardIssuePlaceU,
                Passport,
                PassportValidFrom,
                AddressLine,
                AddressLineU: AddressLine,
                CountryID: CountryID || "VIE" ,
                CountryNameU,
                ProvinceID,
                ProvinceNameU,
                DistrictID,
                DistrictNameU,
                WardID,
                WardName,
                ContactAddressU,
                ContactCountryID: ContactCountryID || "VIE",
                ContactCountryNameU,
                ContactProvinceID,
                ContactProvinceNameU,
                ContactDistrictID,
                ContactDistrictNameU,
                ContactWardID,
                ContactWardNameU,

                //Org
                Telephone_Org,
                Email_Org,
                TaxNo,
                LicenseNo,
                CompanyFaxNo,
                CompanyGroupID,
                RegisteredAddressU,
                RegisteredCountryID: RegisteredCountryID || "VIE",
                RegisteredCountryNameU,
                RegisteredProvinceID,
                RegisteredProvinceNameU,
                RegisteredDistrictID,
                RegisteredDistrictNameU,
                RegisteredWardID,
                RegisteredWardNameU,
                CompanyContactAddressU,
                CompanyContactCountryID: CompanyContactCountryID || "VIE" ,
                CompanyContactCountryNameU,
                CompanyContactProvinceID,
                CompanyContactProvinceNameU,
                CompanyContactDistrictID,
                CompanyContactDistrictNameU,
                CompanyContactWardID,
                CompanyContactWardNameU,
                ObjectID,
                DivisionID,
                PaymentTermID,
                Approved,
                CompanyID_Edit
            };
            this.setState({masterInputData},cb)
        });
    }

    loadDataW13() {
        const uName = Config.profile.UserID || '';
        const uLanguage = Config.language || '';
        const {CompanyID} = this.getInfo();
        const contactID = window.location.search.split('ContactID=')[1];
        const mnParam = {
            "UserID": uName,
            "Language": uLanguage,
            CompanyID,
            "ContactID": contactID,
            "skip": this.filterW18F1013.skip,
            "limit": this.filterW18F1013.limit,
            "StrSearch": this.filterW18F1013.search,
        };
        this.props.w18Actions.loadW18F1013(mnParam, (error) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({isSubmit: false, message: message});
                return false;
            }

        });
        this.loadDataTabAction();
        this.loadGridTabDocument();
    }

    loadDataTabAction() {
        const uName = Config.profile.UserID || '';
        const uLanguage = Config.language || '';
        const {CompanyID} = this.getInfo();
        const paramActionTab = {
            "UserID": uName,
            "Language": uLanguage,
            CompanyID,
            "TaskID": '',
            "skip": this.filter.skip,
            "limit": this.filter.limit,
            "StrSearch": this.filter.search,
        };
        this.props.w18Actions.loadActionTabW18F1012(paramActionTab, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({isSubmit: false, message: message});
                return false;
            } else {
                const {TaskID, customerNew} = this.getInfo();
                if(customerNew && !TaskID && customerNew.CompanyNameU && customerNew.CompanyID){
                    this.addActionTab({
                        CompanyName: customerNew.CompanyNameU,
                        CompanyID: customerNew.CompanyID,
                    });
                    setTimeout(()=>{
                        const state = this.props.location.state;
                        state.customerNew = null;
                        state.showPopup= false;
                        browserHistory.push({
                            pathname: Config.getRootPath() + 'W17F1026',
                            state: state
                        })
                    },100);
                }
                if (data.length > 0){
                    this.totalRecordsActionTab = data[data.length - 1].TotalRecord;
                    if((TaskID) && this.firstTime){
                        const item = data.find(i=>i.TaskID === TaskID);
                        if(item && !customerNew){
                            this.viewActionTab({row:{data:item}});
                        }
                        else{
                            if(customerNew && customerNew.CompanyNameU && customerNew.CompanyID){
                                item.CompanyName = customerNew ? customerNew.CompanyNameU : '';
                                item.CompanyID = customerNew ? customerNew.CompanyID : '';
                            }
                            this.editActionTab({row:{data:item}});
                            setTimeout(()=>{
                                const state = this.props.location.state;
                                state.customerNew = null;
                                state.showPopup= false;
                                state.TaskID= null;
                                browserHistory.push({
                                    pathname: Config.getRootPath() + 'W17F1026',
                                    state: state
                                })
                            },100);
                        }
                    }
                }
            }

        });

    }

    //TAB Document
    loadGridTabDocument() {
        const {CompanyID} = this.getInfo();
        // const where = "KeyID='" + companyID + "' AND TableName='D17T1010'";
        const where = JSON.stringify(
            {
                fieldName: 'KeyID',
                operator: '=',
                value: CompanyID
            });
        const param = {
            "where": where,
            "skip": this.filter.skip,
            "limit": this.filter.limit,
            "search": this.filter.search,
            "tableName": 'D17T1010'
        };
        this.props.w17Actions.loadW17F0001(param, (error) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        });
    }

    loadData = (cb) => {
        cb && cb(this.dataW18F1014);
    };

    /**
     * @End Load...
     */

    /**
     * @Actions
     */

    //General..
    comeBack =() => {
        const {type = "Customer", screenID} = this.getInfo();
        const FormID = `W17F1025`
        if (screenID) {
            browserHistory.goBack();
        }
        browserHistory.push(Config.env.url + `/${FormID}?type=${type}`);
    }

    Detail = () => {
        const {location={}} = this.props
        browserHistory.replace({state:{...location.state,mode:"edit"}})
    }

    toggle3() {
        this.setState({collapse3: !this.state.collapse3});
    }

    handleSelect(key) {
        this.setState({key: key});
    }

    deleteRowActionTab(e) {
        const data = e.row.data;
        const param = {
            TaskID: data.TaskID,

        };
        Config.popup.show("YES_NO", Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
            this.props.W17F1026Actions.deleteTask(param, (error) => {
                if (error) {
                    let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                }

                notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 300);
                this.loadDataTabAction();
            })
        })


    }

    closeActionPopup = () => {
        this.loadDataTabAction();
        this.dataW18F1014.rowData = '';
        this.dataW18F1014.mode = '';
        this.setState({showW18F1014: false});
    }

    onAddNew = () => {
        const {CompanyID} = this.getInfo();
        this.setState({showPopup: true});
        const rowNew = {
            CompanyID,
            FullNameU: '',
            JobPositionU: '',
            Birthday: '',
            Gender: '',
            ContactStatus: '',
            NoteU: '',
            MobileNo: '',
            OfficeTelNo: '',
            HomeAddressU: '',
            Email: '',
            Email2: '',
            Facebook: '',
            Skype: '',
            IsAdmin: false,
            mode: 'add'
        };
        this.setState({row: rowNew});
    }
    onChangeMode =(mode) =>{
        this.setState({row:{...this.state.row,mode}})
    }
    onEdit(row) {
        const rowEdit = {
            CompanyID: row.CompanyID,
            ContactID: row.ContactID,
            FullNameU: row.FullNameU,
            JobPositionU: row.JobPositionU,
            Birthday: row.Birthday,
            ContactStatus: row.ContactStatus,
            Gender: row.Gender,
            NoteU: row.NoteU,
            MobileNo: row.MobileNo,
            OfficeTelNo: row.OfficeTelNo,
            HomeAddressU: row.HomeAddressU,
            Email: row.Email,
            IsAdmin: row.IsAdmin,
            Email2: row.Email2,
            Facebook: row.Facebook,
            Skype: row.Skype,
        };
        this.setState({row: rowEdit});
        this.setState({showPopup: true});
    }

    onDelete(row) {
        Config.popup.show("YES_NO", Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
            const mnParam = {
                "CompanyID": row.CompanyID,
                "ContactID": row.ContactID,
            };
            this.props.w18Actions.deleteW18F1013(mnParam, (error) => {
                if (error) {
                    let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    this.setState({isSubmit: false, message: message});
                    return false;
                } else {
                    notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 2000);
                    this.loadDataW13();
                }
            });
        });

    }

    onChangePageW13(page) {
        this.filterW18F1013.skip = page * this.filterW18F1013.limit;
        this.loadDataW13();
    }

    onChangePerPageW13(per) {
        this.filterW18F1013.skip = 0;
        this.filterW18F1013.limit = per;
        this.loadDataW13();
    }

    onSearchW13(text) {
        this.filterW18F1013.skip = 0;
        this.filterW18F1013.search = text;
        this.loadDataW13();
    }

    hideAddNewPopup() {
        this.setState({showPopup: false});
        this.loadDataW13();
    }

    onChangePageTabAction(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadDataTabAction();
    }

    onChangePerPageTabAction(per) {
        this.filter.limit = per;
        this.loadDataTabAction();
    }

    onSearchTabAction(text) {
        this.filter.search = text;
        this.loadDataTabAction();
    }

    editActionTab(e) {
        const data = e.row.data;
        this.dataW18F1014.mode = 'edit';
        this.dataW18F1014.rowData = data;
        this.setState({
            showW18F1014: true,
        },()=>{
            if(this.firstTime){
                this.firstTime = false;
            }
        })
    }

    viewActionTab = (e) => {
        const data = e.row.data;
        this.dataW18F1014.mode = 'view';
        this.dataW18F1014.rowData = data;
        this.setState({
            showW18F1014: true,
        },()=>{
            if(this.firstTime){
                this.firstTime = false;
            }
        })
    };

    addActionTab = (data) => {
        const {masterInputData} = this.state;
        const {CompanyNameU:CompanyName = "",CompanyID = ""} = masterInputData || {}
        this.dataW18F1014.mode = 'add';
        if(data && data.CompanyName && data.CompanyID){
            this.dataW18F1014.rowData = data;
        }
        else{
            this.dataW18F1014.rowData = {
                CompanyName,CompanyID
            };
        }
        this.setState({showW18F1014: true})
    };

    onDeleteTabDocument(row) {
        Config.popup.show("YES_NO", Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
            const param = {"AttachmentID": row.AttachmentID};
            this.props.w17Actions.deleteW17F0001(param, (error) => {
                if (error) {
                    let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                } else {
                    notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 2000);
                    this.loadGridTabDocument();
                }
            });
        });
    }

    onDownloadDocument = (row) => {
        let element = document.createElement('a');
        element.setAttribute('href', row.URL);
        element.setAttribute('target', "_blank");
        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    };

    onEditTabDocument(row) {
        const rowEdit = {
            tableName: row.TableName,
            notes: row.NotesU,
            description: row.DescriptionU,
            fileName: row.FileName,
            fileSize: row.FileSize,
            url: row?.url || "",
            createDate: row.CreateDate,
        };
        this.setState({
            attachmentID: row.AttachmentID,
            rowDoc: rowEdit,
            showAttachment: true
        });
    }

    onChangePageTabDocument(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadGridTabDocument();
    }

    onChangePerPageTabDocument(per) {
        this.filter.limit = per;
        this.loadGridTabDocument();
    }

    onSearchTabDocument(text) {
        this.filter.search = text;
        this.loadGridTabDocument();
    }

    onAddNewTabDocument() {
        const rowAdd = {
            tableName: '',
            notes: '',
            description: '',
            fileName: '',
            fileSize: 0,
            url: '',
            createDate: '',
        };
        this.setState({
            attachmentID: '',
            rowDoc: rowAdd,
            showAttachment: true
        });
    }


    hideTabDocumentPopup() {
        this.setState({showAttachment: false});
    }

    onSendMail() {
        this.setState({showSendMail: true});
    }

    hideSendMailPopup() {
        this.setState({showSendMail: false});
    }

    trimValue = (str = "") => {
        if (typeof str !== 'string' || Config.isEmpty(str, false)) return '';
        return str.trim();
    };

    //save
    getParamsSave = () =>{
        const { mode } = this.getInfo()
        const { masterInputData } = this.state;
        const CompanyNameU = this.trimValue(masterInputData.CompanyNameU);
        const FullName = this.trimValue(masterInputData.FullName);
        const MobileNo_NLH = this.trimValue(masterInputData.MobileNo_NLH);
        const Email2_NLH = this.trimValue(masterInputData.Email2_NLH);
        let {
            IsPerson,
            CompanyID,
            CompanyTypeID,
            CompanyShortU,
            // CompanyNameU,
            CaseSourceID,
            IndustryGroupID,
            CustomerLevelID,
            GroupSalesID,
            SalesPersonID,
            NotesU,

            //nlh
            ContactID,
            // FullName,
            // MobileNo_NLH,
            // Email2_NLH,
            IsContactAdmin,
            Gender_NLH,
            //legal
            LegalPersonU,
            LTitleU,
            LBirthday,
            LIDCardNo,
            LIDCardIssueDate,
            LIDCardIssuePlaceU,
            LMobileNo,
            LEmail,
            LAddressLineU,
            LContactAddressU,

            //person
            Telephone,
            Email,
            DateOfBirth,
            Gender,
            IDCardNo,
            IDCardIssueDate,
            IDCardIssuePlaceU,
            Passport,
            PassportValidFrom,
            AddressLineU,
            CountryID,
            CountryNameU,
            ProvinceID,
            ProvinceNameU,
            DistrictID,
            DistrictNameU,
            WardID,
            WardName,
            ContactAddressU,
            ContactCountryID,
            ContactCountryNameU,
            ContactProvinceID,
            ContactProvinceNameU,
            ContactDistrictID,
            ContactDistrictNameU,
            ContactWardID,
            ContactWardNameU,

            //Org
            Telephone_Org,
            Email_Org,
            TaxNo,
            LicenseNo,
            CompanyFaxNo,
            CompanyGroupID,
            RegisteredAddressU,
            RegisteredCountryID,
            RegisteredCountryNameU,
            RegisteredProvinceID,
            RegisteredProvinceNameU,
            RegisteredDistrictID,
            RegisteredDistrictNameU,
            RegisteredWardID,
            RegisteredWardNameU,
            CompanyContactAddressU,
            CompanyContactCountryID,
            CompanyContactCountryNameU,
            CompanyContactProvinceID,
            CompanyContactProvinceNameU,
            CompanyContactDistrictID,
            CompanyContactDistrictNameU,
            CompanyContactWardID,
            CompanyContactWardNameU,
            CompanyID_Edit

        }  = this.state.masterInputData || {}
        DateOfBirth = DateOfBirth? moment.utc(DateOfBirth).format("YYYY-MM-DD") : null
        IDCardIssueDate = IDCardIssueDate? moment.utc(IDCardIssueDate).format("YYYY-MM-DD") : null
        PassportValidFrom = PassportValidFrom? moment.utc(PassportValidFrom).format("YYYY-MM-DD") : null
        LIDCardIssueDate = LIDCardIssueDate? moment.utc(LIDCardIssueDate).format("YYYY-MM-DD") : null
        LBirthday = LBirthday? moment.utc(LBirthday).format("YYYY-MM-DD") : null
        const generalParams ={
            IsPerson,
            CompanyID,
            CompanyTypeID,
            CompanyShortU,
            CompanyNameU,
            CaseSourceID,
            IndustryGroupID,
            CustomerLevelID,
            GroupSalesID,
            SalesPersonID,
            NotesU,

            // nlh
            ContactID,
            FullName,
            MobileNo_NLH,
            Email2_NLH,
            IsContactAdmin,
            Gender_NLH,
            //legal
            LegalPersonU,
            LTitleU,
            LBirthday,
            LIDCardNo,
            LIDCardIssueDate,
            LIDCardIssuePlaceU,
            LMobileNo,
            LEmail,
            LAddressLineU,
            LContactAddressU,
        }
        const personParams ={
            //person
            Telephone,
            Email,
            DateOfBirth,
            Gender,
            IDCardNo,
            IDCardIssueDate,
            IDCardIssuePlaceU,
            Passport,
            PassportValidFrom,
            AddressLineU,
            CountryID,
            CountryNameU,
            ProvinceID,
            ProvinceNameU,
            DistrictID,
            DistrictNameU,
            WardID,
            WardName,
            ContactAddressU,
            ContactCountryID,
            ContactCountryNameU,
            ContactProvinceID,
            ContactProvinceNameU,
            ContactDistrictID,
            ContactDistrictNameU,
            ContactWardID,
            ContactWardNameU,
        }
        const companyParams ={//Org
            Telephone_Org,
            Email_Org,
            TaxNo,
            LicenseNo,
            CompanyFaxNo,
            CompanyGroupID,
            RegisteredAddressU,
            RegisteredCountryID,
            RegisteredCountryNameU,
            RegisteredProvinceID,
            RegisteredProvinceNameU,
            RegisteredDistrictID,
            RegisteredDistrictNameU,
            RegisteredWardID,
            RegisteredWardNameU,
            CompanyContactAddressU,
            CompanyContactCountryID,
            CompanyContactCountryNameU,
            CompanyContactProvinceID,
            CompanyContactProvinceNameU,
            CompanyContactDistrictID,
            CompanyContactDistrictNameU,
            CompanyContactWardID,
            CompanyContactWardNameU,}
        const result = {...generalParams,...personParams,...companyParams}
        result.Gender = result.Gender === "F" ? "F" : "M"
        result.Gender_NLH = result.Gender_NLH === "F" ? "F" : "M"
        result.MobileNo = "";
        if (mode === 'edit') result.CompanyID = CompanyID_Edit;
        return result

    }
    checkParamsSave =(params) => {
       const {type} = this.getInfo()
       if (!params.CompanyID) {
        return `${Config.lang("CRM_Ban_chua_nhap_gia_tri")}: ${Config.lang(this.getLabel(type,"CompanyID"))}!`
       }
       if (!params.CompanyNameU) {
        return `${Config.lang("CRM_Ban_chua_nhap_gia_tri")}: ${Config.lang(this.getLabel(type,"CompanyName"))}!`
       }
       if (params.IsPerson === 1) {
        if (!params.Telephone) {
            return `${Config.lang("CRM_Ban_chua_nhap_gia_tri")}: ${Config.lang("CRM_So_dien_thoai")}!`
        }else{
            let rgx = /^\d{10,}$/
            if ( !rgx.test(params.Telephone)) {
                    return `${Config.lang("CRM_So_dien_thoai_khong_hop_le")}!`
            }

        }
        }else{
            if (!params.Telephone_Org) {
                return `${Config.lang("CRM_Ban_chua_nhap_gia_tri")}: ${Config.lang("CRM_So_dien_thoai")}!`
            }else{
                let rgx = /^\d{10,}$/
                if ( !rgx.test(params.Telephone_Org)) {
                        return `${Config.lang("CRM_So_dien_thoai_khong_hop_le")}!`
                }

            }
        }
        if (params.IsContactAdmin !== 1) {
            if (!params.MobileNo_NLH) {
                return `${Config.lang("CRM_Ban_chua_nhap_gia_tri")}: ${Config.lang("CRM_So_dien_thoai_nguoi_lien_he")}!`

            }else{
                let rgx = /^\d{10,}$/
                if ( !rgx.test(params.MobileNo_NLH)) {
                    return `${Config.lang("CRM_Gia_tri_khong_hop_le")}: ${Config.lang("CRM_So_dien_thoai_nguoi_lien_he")}!`
                }
            }
            if (!params.Email2_NLH) {
            return `${Config.lang("CRM_Ban_chua_nhap_gia_tri")}: ${Config.lang("CRM_Email")}!`
            }
            if (!params.FullName) {
            return `${Config.lang("CRM_Ban_chua_nhap_gia_tri")}: ${Config.lang("CRM_Ho_va_ten_nguoi_lien_he")}!`
            }
       }
       return false

    }
    onSave =() =>{
        const {mode,type } = this.getInfo()
        let inputParams  =  this.getParamsSave()
        let inputError  = this.checkParamsSave(inputParams)
        if (inputError) {
            Config.popup.show("INFO",inputError)
            return false
        }
        let params = {
            ...inputParams,
            CompanyKindID: type === "Partner" ? "T001" : type === "Competitor" ? "T004" : "T003"
        }
        if (mode === "add") {
            params.Type = type
            this.setState({loading: true});
            this.props.W17F1026Actions.addNew(params,(error,data) => {
                this.setState({ loading:false });
                if (error || !data) {
                    Config.popup.show("INFO",error.message || Config.lang("CRM_Co_loi_xay_ra_trong_qua_trinh_xu_ly"))
                    return false
                }
                if (data.Status === 0) {
                    notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
                    this.setState({isSaved:true})
                    this.changeLocatioState({
                        mode: "view",
                        CompanyTypeID:params.CompanyTypeID,
                        CompanyID: params.CompanyID,
                        Permission: 4
                    })
                    this.isPermission = 4;
                    this.loadFormMaster();
                    this.loadDataW13();
                    return false
                }
                if (data.Status === 1) {
                    if (data.MsgAsk === 1) {
                        Config.popup.show("YES_NO", data.Message || Config.lang("CRM_Ban_co_chac_chan_muon_sua_du_lieu_nay"), () => {
                                    this.props.W17F1026Actions.confirmSave({code: data.code}, (error,data) =>{
                                        if (error || !data) {
                                            Config.popup.show("INFO",error.message || Config.lang("CRM_Co_loi_xay_ra_trong_qua_trinh_xu_ly"))
                                            return false
                                        }

                                        if (data.Status === 0) {
                                            notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
                                            this.setState({isSaved:true})

                                            this.loadFormMaster(()=>this.changeLocatioState({
                                                mode: "view",
                                                CompanyTypeID:params.CompanyTypeID,
                                                CompanyID: params.CompanyID,
                                                Permission: 4
                                            }))
                                            this.isPermission = 4
                                            this.loadDataW13();
                                            return false
                                        }
                                    })
                        });
                        return false

                    }
                    this.setState({message: data.Message});
                    Config.popup.show("INFO", data.Message);

                }
            })

        }
        if (mode === "edit") {
            this.setState({ loading: true });
            this.props.W17F1026Actions.edit(params,(error,data) => {
                this.setState({ loading: false });
                if (error || !data) {
                    Config.popup.show("INFO",error.message || Config.lang("CRM_Co_loi_xay_ra_trong_qua_trinh_xu_ly"))
                    return false
                }

                if (data.Status === 0) {
                    notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
                    this.loadFormMaster(() => this.changeLocatioState({
                        mode: "view",
                        CompanyTypeID: params.CompanyTypeID,
                        CompanyID: params.CompanyID
                    }));
                    this.loadDataW13();
                    return false
                }
                if (data.Status === 1) {
                    if (data.MsgAsk === 1) {
                        Config.popup.show("YES_NO", data.Message || Config.lang("CRM_Ban_co_chac_chan_muon_sua_du_lieu_nay"), () => {
                            this.setState({ loading: true });
                            this.props.W17F1026Actions.confirmSave({ code: data.code }, (error, data) => {
                                this.setState({ loading: false });
                                if (error || !data) {
                                    Config.popup.show("INFO", error.message || Config.lang("CRM_Co_loi_xay_ra_trong_qua_trinh_xu_ly"))
                                    return false
                                }

                                if (data.Status === 0) {
                                    notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
                                    this.setState({ isSaved: true })

                                    this.loadFormMaster(() => this.changeLocatioState({
                                        mode: "view",
                                        CompanyTypeID: params.CompanyTypeID,
                                        CompanyID: params.CompanyID,
                                        Permission: 4
                                    }));
                                    this.loadDataW13();
                                    return false
                                }
                            })
                        });
                        return false

                    }
                    this.setState({ message: data.Message });
                    Config.popup.show("INFO", data.Message);
                }
            })
        }


        //save
    }
    changeLocatioState =(state) =>{
        let {location = {}} = this.props
        location.state ={
            ...(location.state || {}),
            ...(state ||{})
        }
        browserHistory.replace(location)

    }
    /**
     * @End Actions..
     */

    /**
     * @Render
     */

    renderActionDoc(e, disabledAction) {
        if (!e) return null;
        return (
            <GridActionBar
                isPer={!disabledAction && this.state.iPermissionDoc}
                classNameIs={this.isPermission}
                className={(this.isPermission >= 4 && !disabledAction) ? '' : 'isDisabled'}
                tooltipEdit={Config.lang("CRM_Sua")}
                onEditAction={() => {
                    this.onEditTabDocument(e.row.data)
                }}

                tooltipDelete={Config.lang("CRM_Xoa")}
                onDeleteAction={() => {
                    this.onDeleteTabDocument(e.row.data)
                }}

                tooltipDownload={Config.lang("CRM_Tap_tin")}
                onDownloadAction={() => {
                    this.onDownloadDocument(e.row.data)
                }}/>
        );
    }
    onChangeMasterInput = (name,value,name2,value2) => {
        const masterInputData = {...this.state.masterInputData}
        switch (name) {
            default:
                masterInputData[name] = value

        }
        if (name2) {
            switch (name2) {
                default:
                    masterInputData[name2] = value2

            }
        }

        this.setState({masterInputData})
    }
    onChangeMasterInputs = (data ={}) => {
        const masterInputData = {...this.state.masterInputData,...data}
        this.setState({masterInputData})
    }
    onModeChange = mode =>{
        this.changeLocatioState({mode})
    }
    onAddMore =() =>{
        this.setState({
            isSaved: false,
            masterInputData:{
                CompanyID: "",
                CompanyTypeID: "TN",
                IsPerson: 0,
                CompanyShortU: '',
                CompanyNameU: '',
                CaseSourceID: '',
                IndustryGroupID: '',
                CustomerLevelID: '',
                GroupSalesID: '',
                SalesPersonID: '',
                NotesU: '',

                //nlh
                ContactID:"",
                FullName: '',
                MobileNo_NLH : '',
                Gender_NLH: "M",
                Email2_NLH : '',
                //legal
                LegalPersonU: '',
                LTitleU: '',
                LBirthday: '',
                LIDCardNo: '',
                LIDCardIssueDate: '',
                LIDCardIssuePlaceU: '',
                LMobileNo: '',
                LEmail: '',
                LAddressLineU: '',
                LContactAddressU: '',
                IsContactAdmin: 0,

                //person
                Telephone: '',
                Email: '',
                DateOfBirth: '',
                Gender: 'M',
                IDCardNo: '',
                IDCardIssueDate: '',
                IDCardIssuePlaceU: '',
                Passport: '',
                PassportValidFrom: '',
                AddressLineU: '',
                CountryID: 'VIE',
                CountryNameU: '',
                ProvinceID: '',
                ProvinceNameU: '',
                DistrictID: '',
                DistrictNameU: '',
                WardID: '',
                WardName: '',
                ContactAddressU: '',
                ContactCountryID: 'VIE',
                ContactCountryNameU: '',
                ContactProvinceID: '',
                ContactProvinceNameU: '',
                ContactDistrictID: '',
                ContactDistrictNameU: '',
                ContactWardID: '',
                ContactWardNameU: '',

                //Org
                Telephone_Org: '',
                Email_Org: '',
                TaxNo: '',
                LicenseNo: '',
                CompanyFaxNo: '',
                CompanyGroupID: '',
                RegisteredAddressU: '',
                RegisteredCountryID: 'VIE',
                RegisteredCountryNameU: '',
                RegisteredProvinceID: '',
                RegisteredProvinceNameU: '',
                RegisteredDistrictID: '',
                RegisteredDistrictNameU: '',
                RegisteredWardID: '',
                RegisteredWardNameU: '',
                CompanyContactAddressU: '',
                CompanyContactCountryID: 'VIE',
                CompanyContactCountryNameU: '',
                CompanyContactProvinceID: '',
                CompanyContactProvinceNameU: '',
                CompanyContactDistrictID: '',
                CompanyContactDistrictNameU: '',
                CompanyContactWardID: '',
                CompanyContactWardNameU: '',
            }
        },()=> {
            this.props.dispatch(w18Actions.loadActionTabW18F1012Complete([]));
            this.props.dispatch(w18Actions.loadW18F1013Complete([]));
            this.props.dispatch(w17Actions.loadW17F0001Complete(null));
            this.changeLocatioState({mode: "add", CompanyTypeID: undefined,CompanyID: undefined});
        })
    }
    renderActionActivity(e,disabledAction) {
        if (!e) return null;
        return (
            <GridActionBar
                isPer={!disabledAction && this.state.iPermissionActivity}
                classNameIs={this.isPermission}
                className={(this.isPermission >= 4 && !disabledAction) ? '' : 'isDisabled'}
                tooltipEdit={Config.lang("CRM_Sua")}
                onEditAction={() => {
                    this.editActionTab(e)
                }}

                tooltipDelete={Config.lang("CRM_Xoa")}
                onDeleteAction={() => {
                    this.deleteRowActionTab(e)
                }}/>
        );
    }

    renderActionContact(e ,disabledAction) {
        if (!e) return null;
        return (
            <GridActionBar
                isPer={!disabledAction && this.state.iPermissionContact}
                classNameIs={this.isPermission}
                className={(this.isPermission >= 4 && !disabledAction)? '' : 'isDisabled'}
                tooltipEdit={Config.lang("CRM_Sua")}
                onEditAction={() => {
                    this.onEdit(e.row.data)
                }}

                tooltipDelete={Config.lang("CRM_Xoa")}
                onDeleteAction={() => {
                    this.onDelete(e.row.data)
                }}/>
        );
    }

    renderCompanyName = (e) => {
        if (!e) return null;
        /* eslint-disable */
        return (
            <a onClick={() => {
                this.viewActionTab(e)
            }}>{e.row.data.TitleU}</a>
        );
        /* eslint-enable */
    };

    renderIsAttach = (e) => {
        if (!e || e.row.data.CountAttach < 1) return null;
        return (
            <div>{e.row.data.CountAttach}&nbsp;&nbsp;<i className={"fas fa-paperclip text-orange "}/></div>
        )
    };

    /**
     * @End Render..
     */

    btnConvertAction = () => {
        const {companyID: CompanyID = ""} = this.getInfo();
        const param = {
            CompanyID: CompanyID,
        }
        this.setState({loading: true});
        this.props.W17F1026Actions.btnConvertAction(param, (error, data) => {
            this.setState({loading: false});
            if (error) {
                let message = error ? error.message : Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                if(data && data.Status === 0){
                    this.setState({disableConvertBtn: true});
                    notify(Config.lang("Yeu_cau_chuyen_doi_thanh_cong"), "success", 2000)
                }else if (data && data.Status !== 0) {
                    let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                }
            }
        });
    };

     getLabel =(type, label)=>{
        const obj = labelConstants[type] || labelConstants.default

        return obj[label] || label

    }
    render() {
        const { iPermission,masterInputData} = this.state;
        if (!this.getInfo(true) || !iPermission) return null;
        const {CompanyID, type, TaskID,mode} = this.getInfo();
        const {actionTabDataW18F1012, loadW18F1013, loadW17F0001} = this.props;
        const {loading,isSaved} = this.state;
        let totalItemsW13 = 0;
        let totalItemDocumentTab = 0;
        let dataSourceW13 = [];
        let dsDocument = [];
        if (loadW18F1013 && loadW18F1013.length > 0) {
            loadW18F1013.map(o => {
                const row = {
                    CompanyID: o.CompanyID,
                    ContactID: o.ContactID,
                    FullNameU: o.FullNameU,
                    JobPositionU: o.JobPositionU,
                    Gender: o.Gender,
                    GenderName: o.GenderName,
                    Birthday: o.Birthday,
                    IsAdmin: o.IsAdmin,
                    MobileNo: o.MobileNo,
                    OfficeTelNo: o.OfficeTelNo,
                    HomeAddressU: o.HomeAddressU,
                    ContactStatus: o.ContactStatus,
                    NoteU: o.NoteU,
                    Email: o.Email,
                    Email2: o.Email2,
                    Facebook: o.Facebook,
                    Skype: o.Skype,
                    ContactStatusName: o.ContactStatusName,
                };
                totalItemsW13 = o.TotalRecord;
                dataSourceW13.push(row);
                return o;
            });
        }
        if (loadW17F0001) {
            loadW17F0001.data.rows.map(o => {
                const row = {
                    CompanyID,
                    DescriptionU: o.DescriptionU,
                    NotesU: o.NotesU,
                    FileName: o.FileName,
                    CreateDate: o.CreateDate,
                    url: o.URL,
                    URL: Config.env.api + '/file/get?AttachmentID=' + o.AttachmentID,
                    AttachmentID: o.AttachmentID,
                };
                totalItemDocumentTab = loadW17F0001.data.total;
                dsDocument.push(row);
                return o;
            });
        }
        const authProduct = Config.getLocalStorage("AUTHPRODUCT");

        const disabledAction = mode === "add"
        return (
            <div className="page-container" style={{margin: 0,marginBottom:20}}>

               {Config && Config.profile &&
                    <EventTracking category={"TRACKING_USER"} action={"W17F1026"} label={Config.profile.UserID} />}


                {this.state.showW18F1014 &&
                <ActionTab loadData={this.loadData}
                           showComment={this.firstTime && TaskID}
                           iPermission={iPermission}
                           data={this.dataW18F1014}
                           formID={'W17F1026'}
                           state={this.getInfo()}
                           onHide={() => this.closeActionPopup()}

                />
                }
                <div className="grid-title" style={{ marginLeft: '10px' }}>{Config.lang(this.getLabel(type,"FormTitle"))}</div>
                <W17F1026Master
                    onRef={ref => this.w17F1026MasterRef = ref}
                    iPermission={iPermission}
                    iPermissionConvert={this.state.iPermissionConvert}
                    disableConvertBtn={this.state.disableConvertBtn}
                    stateData ={this.getInfo()}
                    getLabel =  {(label)=>this.getLabel(type,label)}
                    inputData ={masterInputData}
                    onChangeInput = {this.onChangeMasterInput}
                    onChangeMasterInputs ={this.onChangeMasterInputs}
                    comeBack={this.comeBack}
                    onSave = {this.onSave}
                    isSaved = {isSaved}
                    onAddMore ={this.onAddMore}
                    btnConvertAction={this.btnConvertAction}
                    onEdit ={e => {
                        this.onModeChange("edit")
                        this.setState({isSaved: false})
                    }}
                />
                <Form horizontal={true} style={{margin: '0 15px'}} onSubmit={(e) => e.preventDefault()}>

                    <FormGroup>

                        <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                            {(this.state.iPermissionActivity > 0 || this.state.iPermissionDelivery > 0 || this.state.iPermissionContact > 0 || this.state.iPermissionDoc > 0 || this.state.iPermissionW18F1018 > 0 || this.state.iPermissionW18F1017 > 0) &&
                                !["WBEM"].includes(authProduct) &&
                            <Tabs
                                defaultActiveKey={this.state.iPermissionActivity > 0 ? 1 : (this.state.iPermissionContact > 0 ? 2 : 3)}
                                id="tabMain"
                                activeKey={this.state.key}
                                animation={true}
                                unmountOnExit={true}
                                mountOnEnter={true}
                                onSelect={(key) => this.handleSelect(key)}
                            >
                                {this.state.iPermissionActivity > 0 &&
                                <Tab eventKey={1} title={Config.lang("CRM_Hoat_Dong")}>
                                    <GridContainer
                                        allowSearch ={disabledAction ? "isDisabled" : ""}
                                        disabledAddNew ={disabledAction}
                                        allowAdding={this.isPermission}
                                        isPer={this.state.iPermissionActivity}
                                        itemPerPage={this.filter.limit}
                                        totalItems={Number(this.totalRecordsActionTab)}
                                        //itemPerPage={this.filter.limit}
                                        onAddNew={this.addActionTab}
                                        onSearch={(text) => {
                                            this.onSearchTabAction(text)
                                        }}
                                        onChangePage={(page) => {
                                            this.onChangePageTabAction(page)
                                        }}
                                        onChangePerPage={(per) => {
                                            this.onChangePerPageTabAction(per)
                                        }}
                                        dataSource={actionTabDataW18F1012}>

                                        <Column
                                            cellRender={(e) => this.renderActionActivity(e,disabledAction)}
                                            dataField={'Action'}
                                            caption={Config.lang("CRM_Hanh_dong")}
                                            alignment={'center'}
                                            allowSorting={false}
                                            showInColumnChooser={false}
                                            width={120}
                                            fixed={true}

                                        />
                                        <Column
                                            dataField={'TitleU'}
                                            caption={Config.lang("CRM_Ten_Cong_viec")}
                                            allowSorting={true}
                                            allowReordering={false}
                                            width={350}
                                            cellRender={this.renderCompanyName}
                                        />
                                        <Column
                                            dataField={'SalesPersonName'}
                                            caption={Config.lang("CRM_Nguoi_phu_trach")}
                                            allowSorting={true}
                                            allowReordering={false}
                                            width={200}
                                        />

                                        <Column
                                            dataField={'ContactName'}
                                            caption={Config.lang("CRM_Nguoi_lien_he")}
                                            width={200}
                                        />
                                        <Column
                                            dataField={'TaskTypeName'}
                                            width={200}

                                            caption={Config.lang("CRM_Loai_cong_viec")}/>

                                        <Column
                                            cellRender={(e) => {
                                                const data = e.data;
                                                return `${data.TaskDateFrom ? moment(data.TaskDateFrom).format("DD/MM/YYYY hh:mm A") : ''} ${data.TaskDateFrom && data.TaskDateTo ? '-' : ''} ${data.TaskDateTo ? moment(data.TaskDateTo).format("DD/MM/YYYY hh:mm A") : ''}`;
                                            }}
                                            dataField={'TaskDateTo'}
                                            caption={Config.lang("CRM_Thoi_gian_thuc_hien")}
                                            width={300}

                                        />
                                        <Column
                                            dataField={'TaskStatusName'}
                                            caption={Config.lang("CRM_Trang_thai")}
                                            width={200}
                                        />
                                        <Column
                                            dataField={'IsAttach'}
                                            caption={Config.lang("CRM_Tai_lieu")}
                                            alignment={'center'}
                                            width={80}
                                            allowSorting={false}
                                            cellRender={this.renderIsAttach}
                                        />
                                        <Column
                                            dataField={'NotesU'}
                                            caption={Config.lang("CRM_Mo_ta")}
                                            width={200}

                                        />
                                        <Column
                                            dataField={'ActEvaluationU'}
                                            caption={Config.lang("CRM_Danh_gia")}
                                            width={300}

                                        />
                                        <Column
                                            dataField={'ResultsU'}
                                            caption={Config.lang("CRM_Ket_qua")}
                                            width={300}

                                        />

                                    </GridContainer>
                                </Tab>}
                                {this.state.iPermissionContact > 0 &&
                                <Tab eventKey={2} title={Config.lang("CRM_Lien_he")}>
                                    {dataSourceW13 && <GridContainer
                                        allowSearch ={disabledAction ? "isDisabled" : ""}
                                        disabledAddNew ={disabledAction}
                                        allowAdding={this.isPermission}
                                        isPer={this.state.iPermissionContact}
                                        dataSource={dataSourceW13}
                                        onAddNew={() => {
                                            this.onAddNew()
                                        }}
                                        showSendMail={!disabledAction}
                                        onSendMail={() => {
                                            this.onSendMail()
                                        }}
                                        totalItems={totalItemsW13}
                                        itemPerPage={this.filterW18F1013.limit}
                                        onSearch={(text) => {
                                            this.onSearchW13(text)
                                        }}
                                        onChangePage={(page) => {
                                            this.onChangePageW13(page)
                                        }}
                                        onChangePerPage={(per) => {
                                            this.onChangePerPageW13(per)
                                        }}
                                    >
                                        <Column
                                            cellRender={(e) => this.renderActionContact(e , disabledAction)}
                                            dataField={'Action'}
                                            caption={Config.lang("CRM_Hanh_dong")}
                                            alignment={'center'}
                                            allowSorting={false}
                                            showInColumnChooser={false}
                                            width={120}
                                            fixed={true}
                                        />
                                        <Column
                                            dataField={'FullNameU'}
                                            caption={Config.lang("CRM_Nguoi_lien_he")}
                                            allowSorting={true}
                                            allowReordering={false}
                                            width={220}
                                            fixed={window.innerWidth>600}
                                        />
                                        <Column
                                            dataField={'JobPositionU'}
                                            caption={Config.lang("CRM_Chuc_vu")}
                                            allowSorting={true}
                                            allowReordering={false}
                                            width={180}
                                            fixed={window.innerWidth>600}
                                        />
                                        <Column
                                            dataField={'IsAdmin'}
                                            caption={Config.lang("CRM_Lien_he_chinh")}
                                            dataType={'boolean'}
                                            allowSorting={true}
                                            allowReordering={false}
                                            fixed={window.innerWidth>600}
                                            width={120}
                                        />
                                        <Column
                                            dataField={'GenderName'}
                                            caption={Config.lang("CRM_Gioi_tinh")}
                                            width={80}
                                        />
                                        <Column
                                            dataField={'MobileNo'}
                                            width={140}
                                            caption={Config.lang("CRM_So_di_dong")}/>
                                        <Column
                                            dataField={'OfficeTelNo'}
                                            caption={Config.lang("CRM_So_may_ban")}
                                            width={140}
                                        />
                                        <Column
                                            dataField={'Email'}
                                            caption={'Email'}
                                            width={200}
                                        />
                                        <Column
                                            dataField={'Skype'}
                                            caption={'Skype'}
                                            width={200}
                                        />
                                        <Column
                                            dataField={'HomeAddressU'}
                                            caption={Config.lang("CRM_Dia_chi")}
                                            width={260}
                                        />
                                        <Column
                                            dataField={'ContactStatusName'}
                                            caption={Config.lang("CRM_Trang_thai")}
                                            width={200}
                                            cellRender={(e) => {
                                                return <div><i className="fas fa-circle pd5" style={{
                                                    fontSize: '12px',
                                                    color: e && e.data && e.data.ContactStatus === "A" ? "#2FBA27" : "#FFC90E"
                                                }}/>{e.value}</div>
                                            }}
                                            allowSorting={true}
                                        />
                                    </GridContainer>}
                                </Tab>}
                                {this.state.iPermissionDelivery > 0 &&
                                <Tab eventKey={3} title={Config.lang("CRM_Giao_hang")}>
                                    <W17F1026Delivery permission={'D17F1027'}
                                        // companyID={CompanyID}
                                        CompanyID={mode === 'add' ? '' : masterInputData?.CompanyID}
                                        CompanyTypeID={mode === 'add' ? '' : masterInputData?.CompanyTypeID}
                                        ObjectID={masterInputData && masterInputData.ObjectID}
                                        ObjectTypeID={masterInputData && masterInputData.CompanyTypeID}
                                        iPermission={this.state.iPermissionDelivery}
                                        allowAdding={this.isPermission}
                                        disabledAction={disabledAction}
                                    />

                                </Tab>}
                                {this.state.iPermissionDoc > 0 &&
                                <Tab eventKey={4} title={Config.lang("CRM_Tai_lieu")}>
                                    <GridContainer
                                         allowSearch ={disabledAction ? "isDisabled" : ""}
                                         disabledAddNew ={disabledAction}
                                        allowAdding={this.isPermission}
                                        isPer={this.state.iPermissionDoc}
                                        dataSource={dsDocument}
                                        keyExpr={'AttachmentID'}
                                        showBorders={false}
                                        showColumnLines={true}
                                        selection={{mode: 'single'}}
                                        allowColumnReordering={true}
                                        rowAlternationEnabled={true}
                                        onAddNew={() => {
                                            this.onAddNewTabDocument()
                                        }}
                                        totalItems={totalItemDocumentTab}
                                        itemPerPage={this.filter.limit}
                                        onSearch={(text) => {
                                            this.onSearchTabDocument(text)
                                        }}
                                        onChangePage={(page) => {
                                            this.onChangePageTabDocument(page)
                                        }}
                                        onChangePerPage={(per) => {
                                            this.onChangePerPageTabDocument(per)
                                        }}
                                    >
                                        {/*<Paging enabled={false}/>*/}
                                        {/*<Paging defaultPageSize={15}/>*/}
                                        {/*<Pager*/}
                                        {/*showPageSizeSelector={true}*/}
                                        {/*allowedPageSizes={[10, 15, 20]}*/}
                                        {/*showInfo={true}/>*/}
                                        <Column dataField={'DescriptionU'} caption={Config.lang("CRM_Tieu_de")}
                                                width={300}
                                                dataType={'string'}/>
                                        <Column dataField={'NotesU'} caption={Config.lang("CRM_Mo_ta")} width={"100%"}
                                                minWidth={400}
                                                dataType={'string'}/>
                                        <Column
                                            dataField={'FileName'}
                                            caption={Config.lang("CRM_Tai_lieu")}
                                            minWidth={300}
                                            width={"100%"}
                                            alignment='left'
                                        />

                                        <Column dataField={'CreateDate'} caption={Config.lang("CRM_Ngay_cap_nhat")}
                                                alignment='center'
                                                format="dd/MM/yyyy"
                                                width={150} dataType={'date'}/>
                                        <Column
                                            cellRender={(data) => this.renderActionDoc(data, disabledAction)}
                                            dataField={'Action'}
                                            caption={Config.lang("CRM_Hanh_dong")}
                                            alignment={'center'}
                                            allowSorting={false}
                                            showInColumnChooser={false}
                                            width={120}
                                            fixed={true}
                                        />
                                        <Column dataField={'URL'} caption="URL" width={150} visible={false}/>
                                        <Column dataField={'AttachmentID'} caption="AttachmentID" width={150}
                                                visible={false}/>
                                    </GridContainer>
                                </Tab>}
                                {this.state.iPermissionW18F1018 > 0 &&
                                <Tab eventKey={5} title={Config.lang("CRM_Nhom_hang_chu_luc")}>
                                    <W18F1018 companyID={CompanyID} isPermission={this.state.iPermissionW18F1018} disabledAction ={disabledAction}/>
                                </Tab>}
                                {this.state.iPermissionW18F1017 > 0 &&
                                <Tab eventKey={6} title={Config.lang("CRM_Mat_hang_chu_luc")}>
                                    <W18F1017
                                        companyID={CompanyID}
                                        iPermission={this.state.iPermissionW18F1017}
                                        disabledAction ={disabledAction}
                                    />
                                </Tab>}
                                {this.state.iPermissionW17F2025 > 0 &&
                                <Tab eventKey={7} title={Config.lang("CRM_Ghi_nhan_nhu_cau")}>
                                    <W17F1026Request
                                        CompanyID={masterInputData && masterInputData.CompanyID}
                                        CompanyTypeID={masterInputData && masterInputData.CompanyTypeID}
                                        CompanyName={masterInputData && masterInputData.CompanyNameU}
                                        iPermission={this.state.iPermissionW17F2025}
                                        iPermissionPrice={this.state.iPermissionPrice}
                                        location ={this.props.location}
                                        disabledAction ={disabledAction}
                                        currentTabKey={this.state.key}
                                    />
                                </Tab>}
                                {this.state.iPermissionPrice > 0 &&
                                <Tab eventKey={8} title={Config.lang("CRM_Bao_gia")}>
                                    <W17F1026Price
                                        objectID={masterInputData && masterInputData.ObjectID}
                                        CompanyID={masterInputData && masterInputData.CompanyID}
                                        CompanyTypeID={masterInputData && masterInputData.CompanyTypeID}
                                        CompanyName={masterInputData && masterInputData.CompanyNameU}
                                        masterInputData={masterInputData}
                                        iPermission={this.state.iPermissionPrice}
                                        iPermissionOrder={this.state.iPermissionOrder}
                                        disabledAction ={disabledAction}
                                        location={this.props.location}
                                        currentTabKey={this.state.key}
                                    />
                                </Tab>}
                                {this.state.iPermissionOrder > 0 &&
                                <Tab eventKey={9} title={Config.lang("CRM_Don_hang")}>
                                    <W17F1026Order
                                        CompanyID={masterInputData && masterInputData.CompanyID}
                                        CompanyTypeID={masterInputData && masterInputData.CompanyTypeID}
                                        CompanyName={masterInputData && masterInputData.CompanyNameU}
                                        masterInputData={masterInputData}
                                        iPermission={this.state.iPermissionOrder}
                                        disabledAction ={disabledAction}
                                        location={this.props.location}
                                        currentTabKey={this.state.key}
                                    />
                                </Tab>}
                                {this.state.iPermissionCase > 0 &&
                                <Tab eventKey={10} title={Config.lang("CRM_Tinh_huong")}>
                                    <W17F1026Case permission={'D17F1861'} companyID={CompanyID}
                                                  companyName={masterInputData && masterInputData.CompanyNameU}
                                                  address={masterInputData && masterInputData.AddressLine}
                                                  email={masterInputData && masterInputData.Email}
                                                  telephone={masterInputData && masterInputData.Telephone}
                                                  iPermission={this.state.iPermissionCase}
                                                  allowAdding={this.isPermission}
                                                  disabledAction ={disabledAction}
                                                  CompanyTypeID={masterInputData?.CompanyTypeID}
                                                  location ={this.props.location}
                                                  currentTabKey={this.state.key}
                                    />

                                </Tab>}
                            </Tabs>}
                        </Col>


                    </FormGroup>
                    {loading && <Loading/>}
                </Form>
                {this.state.showPopup && <PopupView
                    className={'popup-size-90'}
                    title={Config.lang("CRM_Cap_nhat_lien_he")}
                    show={true}
                    animation={true}
                    onHide={() => this.hideAddNewPopup()}>
                    <Detail data={this.state.row}
                            onHide={() => this.hideAddNewPopup()}
                            onReloadGrid={() => this.loadDataW13()}
                            onAddMore= {this.onAddNew}
                            onChangeMode = {this.onChangeMode}/>
                </PopupView>}
                {this.state.showAttachment && <PopupView
                    title={Config.lang("CRM_Dinh_kem")}
                    show={true}
                    animation={true}
                    onHide={() => this.hideTabDocumentPopup()}>
                    <Attachment attachmentID={this.state.attachmentID}
                                tableName="D17T1010"
                                caption={Config.lang("CRM_Khach_hang")}
                                id={masterInputData && masterInputData.CompanyID}
                                value={masterInputData && masterInputData.CompanyNameU}
                                data={this.state.rowDoc}
                                onHide={() => this.hideTabDocumentPopup()}
                                onReloadGrid={() => this.loadGridTabDocument()}/>
                </PopupView>}
                {this.state.showSendMail && <PopupScroll
                    title={Config.lang("CRM_Gui_mail")}
                    show={true}
                    animation={true}
                    onHide={() => this.hideSendMailPopup()}>
                    <SendMail companyID={window.location.search.split('CompanyID=')[1]}
                              onHide={() => this.hideSendMailPopup()}/>
                </PopupScroll>}
            </div>

        )
    }
}

export default connect(state => ({
        loadW18F1013: state.w18.loadW18F1013,
        deleteW18F1013: state.w18.deleteW18F1013,
        gridDataW17F1026: state.w18.gridDataW17F1026,
        actionTabDataW18F1012: state.w18.actionTabDataW18F1012,
        loadW17F0001: state.w17.loadW17F0001,
        delW17F0001: state.w17.delW17F0001,
        getW17F0001: state.w17.getW17F0001,

    }),
    (dispatch) => ({
        w17Actions: bindActionCreators(w17Actions, dispatch),
        w18Actions: bindActionCreators(w18Actions, dispatch),
        W17F1026Actions: bindActionCreators(W17F1026Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
        dispatch
    })
)(W17F1026Page);
