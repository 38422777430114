/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import moment from "moment";
import 'moment/locale/vi';
import React from 'react';
import {Row, Col, Collapse, Form, FormGroup } from "react-bootstrap";
import Config from "../../../config";
import { DateBox, TreeList } from 'devextreme-react';
import ButtonSave from '../../common/button/button-save';
import ButtonAdd from '../../common/button/button-add';
import ButtonCustom from '../../common/button/button-custom';
import ButtonEdit from '../../common/button/button-edit';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import * as W17F1026Actions from "../../../actions/w17F1026-actions";
import { Column, Selection } from 'devextreme-react/tree-list';
import {DropDownBox} from 'devextreme-react';
import Combo from '../../common/dropdown/combo';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import ButtonConvert                  from "../../common/button/button-convert";
import DynamicStepComponent from "../../common/dynamic-step-progress-bar/dynamic-step-progress-bar";

moment.locale('vi');

  class  W17F1026LocationInputComp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cboWard: [],
            cboDistrict: [],
            cboProvince: [],
            cboCountry: [],

        }


    };
    componentDidMount(){
        this.loadCbo(true)

    }
    loadCbo = (full) =>{
        const { DistrictValue, ProvinceValue, CountryValue,} = this.props
        let  cboWard =[], cboDistrict = [], cboProvince =[]
        if (DistrictValue && this.props.cboWard) {
            cboWard = this.props.cboWard.filter(item => item.ItemParent === DistrictValue)
            if (!full) {
                return this.setState({cboWard, })
            }

        } else {
            cboWard = []
        }
        if (ProvinceValue && this.props.cboDistrict) {
            cboDistrict = this.props.cboDistrict.filter(item => item.ItemParent === ProvinceValue)
            if (!full) {
                return this.setState({cboWard, cboDistrict, })
            }

        } else {
            cboDistrict =[]
        }
        cboProvince =CountryValue && this.props.cboProvince ? this.props.cboProvince.filter(item => item.CountryID === CountryValue) : []
        return this.setState({cboWard, cboDistrict, cboProvince, })
    }
    componentDidUpdate(prevProps, prevState) {
        const { DistrictValue, ProvinceValue, CountryValue,cboProvince,cboDistrict,cboWard} = this.props
        if (prevProps.DistrictValue !== DistrictValue ||
            prevProps.ProvinceValue !== ProvinceValue ||
            prevProps.CountryValue !== CountryValue ||
            prevProps.cboProvince !== cboProvince ||
            prevProps.cboDistrict !== cboDistrict ||
            prevProps.cboWard !== cboWard) {

            this.loadCbo(true)
        }


    }
    onChange = (e,name,cbokey,cboValue)=>{

        if(!this.props.onChangeMasterInputs) {
            return false
        }
        let newState = {}
        const {WardName, DistrictName, ProvinceName, CountryName, WardKey, DistrictKey, ProvinceKey, CountryKey,} = this.props

        if (name) {
            let value = ''
            switch (name) {
                case CountryName:{
                     value = this.props.cboCountry.find(item => item[cbokey] === e.target.value) || {}
                     newState ={
                        [WardName]:"",
                        [DistrictName]: "",
                        [ProvinceName] : "",
                        [CountryName] :value[cboValue] || "",

                        [WardKey]:"",
                        [DistrictKey]: "",
                        [ProvinceKey] : "",
                        [CountryKey] :value[cbokey] || "",
                     }
                    break;
                }
                case ProvinceName:{
                     value = this.state.cboProvince.find(item=>item[cbokey] === e.target.value) || {}
                     newState ={
                        [WardName]:"",
                        [DistrictName]: "",
                        [ProvinceName] :value[cboValue] || "",

                        [WardKey]:"",
                        [DistrictKey]: "",
                        [ProvinceKey] :value[cbokey] || "",
                     }
                    break;
                }
                case DistrictName:{
                     value = this.state.cboDistrict.find(item=>item[cbokey] === e.target.value) || {}
                     newState ={
                        [WardName]:"",
                        [DistrictName] :value[cboValue] || "",

                        [WardKey]:"",
                        [DistrictKey] :value[cbokey] || "",
                     }
                    break;
                }
                case WardName:{
                     value = this.state.cboWard.find(item=>item[cbokey] === e.target.value) || {}
                     newState ={
                        [WardName] :value[cboValue] || "",

                        [WardKey] :value[cbokey] || "",
                     }
                    break;
                }
                default:

            }
            this.props.onChangeMasterInputs(newState)

            return
        }
        this.props.onChange && this.props.onChange(e)



    }
    renderCbo =(label, key,name, value, cbo,cboKey,cboValue, disabled) =>{
        return <>
            <Col md={2} sm={2} xs={6} lg={2}><label
                className={"control-label"}>{label}</label></Col>
            <Col  md={4} sm={4} xs={6} lg={4}>
                <select className={'form-control'}
                    name={key}
                    value={value || ""}
                    onChange={e => this.onChange(e,name,cboKey,cboValue)}
                    disabled = {disabled}
                >
                    <option value={''} />
                    {cbo && cbo.length > 0 && cbo.map((value) => {
                        return (<option key={value[cboKey]}
                            value={value[cboKey]}>{value[cboValue]}</option>)
                    })}
                </select>
            </Col>

        </>
    }
    render() {
        const {cboWard, cboDistrict, cboProvince,} = this.state
        const {AddressLabel, AddressValue, WardValue, DistrictValue, ProvinceValue, CountryValue,
                AddressKey, WardKey, DistrictKey, ProvinceKey, CountryKey,
                WardName, DistrictName, ProvinceName, CountryName,
                 cboCountry, disabled} = this.props
        return ( <>
                <FormGroup>
                    <Col md={2} sm={2} xs={6} lg={2}><label
                        className={"control-label"}>{AddressLabel}</label></Col>
                    <Col md={10} sm={10} xs={6} lg={10}>
                        <input className={'form-control'}
                            name={AddressKey}
                            onChange={this.onChange}
                            value={AddressValue || ""}
                            disabled = {disabled}
                        />
                    </Col>
                </FormGroup>
                <FormGroup>
                    {this.renderCbo(Config.lang("CRM_Quoc_gia"),CountryKey,CountryName,CountryValue,cboCountry,"CountryID","CountryName",disabled)}
                    {this.renderCbo(Config.lang("CRM_Tinh_thanh_pho"),ProvinceKey,ProvinceName,ProvinceValue,cboProvince,"ProvinceID","ProviceName",disabled)}
                </FormGroup>
                <FormGroup>
                    {this.renderCbo(Config.lang("CRM_Quan_huyen"),DistrictKey,DistrictName,DistrictValue,cboDistrict,"DistrictID","DistrictName",disabled)}
                    {this.renderCbo(Config.lang("CRM_XaPhuong"),WardKey,WardName,WardValue,cboWard,"WardID","WardName",disabled)}
                </FormGroup>

      </>)
    }

}
const W17F1026LocationInput =connect(state => ({
    cboCountry: state.w17f1026.cboCountry,
    cboProvince: state.w17f1026.cboProvince,
    cboDistrict: state.w17f1026.cboDistrict,
    cboWard: state.w17f1026.cboWard,
}),
(dispatch) => ({
    W17F1026Actions: bindActionCreators(W17F1026Actions, dispatch),
}))(W17F1026LocationInputComp)
class W17F1026Master extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse0: true,  // thong tin chung
            collapse1: true,  // thong tin chung
            //collapse2: true, // thong tin bo sung
            collapse3: true,// dia ly
            collapse4: true,// dia ly
            //collapse5: true,// dia ly,
            collapse6: true,
            loading:{
                getCompanyID:false,
            },
            isCompanyAuto: false,
            dataProgressStatus: {}

        };

    };
    componentDidMount(){
        this.loadCbo();
        if (this.props.onRef) this.props.onRef(this);

        const {mode} = this.props.stateData ||{};
        const {onChangeInput} = this.props;
        if (mode === "add") {
            const {GroupSalesID = ""} = Config.profile || {};
            this.checkCompanyAuto()
            this.onGetNewCompanyID()
            if (onChangeInput) onChangeInput("GroupSalesID", GroupSalesID);
            this.loadSalesPerson(GroupSalesID);
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const {mode} = this.props.stateData ||{}
        const {mode: preMode} = prevProps.stateData ||{};
        const { CompanyID: CompanyIDCurrent = "" } =  this.props?.inputData || {};
        const { CompanyID: CompanyIDOld = "" } = prevProps?.inputData || {};
        if (mode !== preMode && mode === "add") {
            this.checkCompanyAuto()
            this.onGetNewCompanyID()
        }
        if (CompanyIDOld !== CompanyIDCurrent) {
            this.loadProgressBarStatus();
        } 

    }
    
    loadProgressBarStatus = () => {
        const { inputData } =  this.props;
        const params = {
            CompanyID: inputData?.CompanyID || "",
            FormID: "W17F1026"
        };
        this.props.W17F1026Actions.loadProgressBarStatus(params, (error, data) => {
            if (error) {
                const message = error?.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;

            } else if (data) {
                if (Object.keys(data).length > 0) {
                    const { Status = [], StatusValue = 0 } = data;
                    const ListStatus = [];
                    for (let i = 0; i < Status.length; i++) {
                        const item = Status[i];
                        if (item?.Display !== 1) continue;
                        ListStatus.push({
                            number: item?.StatusID,
                            title: item?.StatusName,
                            info: item?.Info || [],
                            display:  item?.Display
                        });
                    };
                    this.setState({
                        dataProgressStatus: {
                            Status: {
                                ListStatus,
                                TotalStatusID: Status.length ?? 0
                            },
                            StatusValue
                        }
                    });
                }
            }
        })
    };

    checkCompanyAuto =() =>{
        this.props.W17F1026Actions.checkCompanyAuto({},(error,data)=> {
            if (error || !data) {
                return
            }
            this.setState({isCompanyAuto:data.CompanyAuto === 1})
        })
    }
    loadCbo = () => {
        const {inputData ={},onChangeInput} = this.props
        this.props.W17F1026Actions.getCboCompanyType({})
        this.props.W17F1026Actions.getCboIndustryGroup({})
        this.props.W17F1026Actions.getCboCompanyGroup({})
        this.props.W17F1026Actions.getCboCustomerLevel({})
        this.props.W17F1026Actions.getCboCaseSource({})
        this.props.W17F1026Actions.getCboGroupSales({})
        this.props.W17F1026Actions.getCboCountry({},(error,data)=>{
            if (error) {
                return false
            }
            if (!data || !inputData || !onChangeInput) {
                return false
            }
            if (inputData.CountryID && !inputData.CountryNameU) {
                let value = data.find(item => item.CountryID === inputData.CountryID) || {}
                value = value["CountryName"] || ""

               onChangeInput("CountryNameU",value)
            }
            if (inputData.ContactCountryID && !inputData.ContactCountryNameU ) {
                let value = data.find(item => item.CountryID === inputData.ContactCountryID) || {}
                value = value["CountryName"] || ""

               onChangeInput("ContactCountryNameU",value)
            }
            if (inputData.RegisteredCountryID && !inputData.RegisteredCountryNameU) {
                let value = data.find(item => item.CountryID === inputData.RegisteredCountryID) || {}
                value = value["CountryName"] || ""

                onChangeInput("RegisteredCountryNameU",value)
            }
            if (inputData.CompanyContactCountryID && !inputData.CompanyContactCountryNameU) {
                let value = data.find(item => item.CountryID === inputData.CompanyContactCountryID) || {}
                value = value["CountryName"] || ""

                onChangeInput("CompanyContactCountryNameU",value)
            }

        })
        this.props.W17F1026Actions.getCboProvince({})
        this.props.W17F1026Actions.getCboDistrict({})
        this.props.W17F1026Actions.getCboWard({})

    }
    loadSalesPerson =(GroupSalesID = "") =>{
        const {onChangeInput} = this.props;
        let params = {
            GroupSalesID,
            Language: Config.language || "84"
        }
        this.props.W17F1026Actions.getCboSalesPerson(params, (error, data) => {
            const {mode} = this.props.stateData ||{}
            if (onChangeInput && data.length > 0 && mode === "add") {
                onChangeInput("SalesPersonID", data?.[0]?.SalesPersonID || "");
            }
        });
    }
   onChangeInput=(e,name2,value2)=>{
        let {name, value}= e.target
        if (!this.props.onChangeInput) {
            return
        }
        switch (name) {
            case "IsPerson":{
                this.props.onChangeInput("IsPerson",parseInt(value))
                break;
            }
            case "Telephone":{
                if (!/^\d*$/.test(value))  return false

                this.props.onChangeInput("Telephone",value)


                break;
            }
            case "Telephone_Org":{
                if (!/^\d*$/.test(value))  return false

                this.props.onChangeInput("Telephone_Org",value)


                break;
            }
            case "LMobileNo":{
                if (!/^\d*$/.test(value))  return false

                this.props.onChangeInput("LMobileNo",value)
                break;
            }
            case "MobileNo_NLH ":{
                if (!/^\d*$/.test(value))  return false
                this.props.onChangeInput("MobileNo_NLH",value)
                break;
            }
            case "IsContactAdmin":{

                this.props.onChangeInput("IsContactAdmin",e.target.checked ? 1 : 0)
                break;
            }

            default:
                this.props.onChangeInput(name,value,name2,value2)
        }


   }
   onChangeDateInput = (e, name) =>{
       return this.onChangeInput({target:{name,value: e.value}})
   }
   onChangeGroupSales =(e) =>{
        const newValue = e.selectedRowKeys.length > 0 ? e.selectedRowKeys[0] : "";
        if (this.props.onChangeInput) {
            this.props.onChangeInput("GroupSalesID",newValue);
            this.props.onChangeInput("SalesPersonID",'');
            this.loadSalesPerson(newValue)
        }
        if (newValue && this.GroupSalesRef) this.GroupSalesRef.instance.close();
   }
   onChangeGroupSalesDD = (e) =>{
    if (this.props.onChangeInput) {
        this.props.onChangeInput("GroupSalesID",e.value || "");
        this.props.onChangeInput("SalesPersonID",'');
        this.loadSalesPerson(e.value || "")
    }
   }
   onGetNewCompanyID =() =>{

        this.props.W17F1026Actions.getNewKey({},(error,data)=>{
            if (error ||!data || !data.NewKey) {
                return Config.popup.show("INFO",error.Message || Config.lang("CRM_Co_loi_xay_ra_trong_qua_trinh_xu_ly"))
            }
            this.props.onChangeInput && this.props.onChangeInput("CompanyID",data.NewKey)
        })
   }
    toggle(index) {
        if (index===0) {
            this.setState({
                collapse0:!this.state.collapse0,
                collapse1:true,
                //collapse2:!this.state.collapse0,
                collapse3:!this.state.collapse0,
                collapse4:!this.state.collapse0,
                //collapse5:!this.state.collapse0,

            })
            return
        }
        let name =`collapse${index}`
        let collapse0 = false

        this.setState({ [name]: !this.state[name],collapse0 },
            ()=>this.state.collapse1 && (this.state.collapse3 || this.state.collapse4) && this.setState({collapse0:true}));
    }


    renderColapse1 =()=>{
        const {loading,isCompanyAuto} = this.state
        const {
            stateData,
            inputData={},
            cboIndustryGroup=[],
            cboCustomerLevel=[],
            cboCaseSource=[],
            cboSalesPerson=[],
            cboCompanyType=[],
            cboGroupSales=[],
            getLabel,
        } = this.props;
        const {mode,} = stateData || {}
        const disabled =  mode === "view";
        const cboSalesPersonObj = cboSalesPerson.some(item => item.SalesPersonID === inputData.SalesPersonID);
        const isReadOnlyCustomerSetting = this.readOnlyCustomerSetting();

        return  <Collapse in={this.state.collapse1}>
        <div>
            <FormGroup>
                <Col md={2} sm={2} xs={6} lg={2}><label
                                className="control-label">{Config.lang("CRM_Loai")}</label>
                </Col>
                <Col md={4} sm={4} xs={6} lg={4} className={'mgb15'}>
                    <select className={'form-control'}
                        name="CompanyTypeID"
                        value={inputData.CompanyTypeID || ""}
                        onChange={this.onChangeInput}
                        disabled = {true}

                    >
                        <option value={''} />
                        {cboCompanyType && cboCompanyType.length > 0 && cboCompanyType.map((value) => {
                            return (<option key={value.CompanyTypeID}
                                value={value.CompanyTypeID}>{value.CompanyTypeName}</option>)
                        })}
                    </select>
                </Col>

                <Col md={2} sm={2} xs={6} lg={2}/>
                <Col md={4} sm={4} xs={6} lg={4} className={'mgb15'}>
                    <div className = "display_row align-around">
                    <label className="radio-inline">
                        <input

                            type="radio"
                            name="IsPerson"
                            value={1}
                            checked={inputData.IsPerson === 1}
                            onChange={this.onChangeInput}
                            disabled = {disabled}
                        />
                        {Config.lang("CRM_Ca_nhan")}
                    </label>
                    <label className="radio-inline">
                        <input
                            type="radio"
                            name="IsPerson"
                            value={0}
                            checked={inputData.IsPerson !== 1}
                            onChange={this.onChangeInput}
                            disabled = {disabled}
                        />
                        {Config.lang("CRM_To_chuc")}
                    </label>
                    </div>

                </Col>
            </FormGroup>
            <FormGroup>
                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang(getLabel("CompanyID"))}<span
                        className={'text-red'}> (*)</span>
                        {
                            mode === "add" && <i  style ={{cursor:"pointer"}} onClick={() => isCompanyAuto && this.onGetNewCompanyID()}
                            className={`fas fa-sync mgl20 text-blue ${loading.getCompanyID? "fa-spin" : ""} ${isCompanyAuto?"" : "isDisabled"}`}/>
                        }
                        </label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                    <input className={'form-control'}
                        name="CompanyID"
                        onChange={this.onChangeInput}
                        value={inputData.CompanyID || ""}
                        disabled = {disabled || mode ==="edit" || isCompanyAuto}
                    />
                </Col>

                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Ten_tat")}</label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                    <input className={'form-control'}
                        name="CompanyShortU"
                        onChange={this.onChangeInput}
                        value={inputData.CompanyShortU || ""}
                        disabled = {disabled}
                    />
                </Col>
            </FormGroup>
            <FormGroup>
                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang(getLabel("CompanyName"))}<span
                        className={'text-red'}> (*)</span></label></Col>
                <Col md={10} sm={10} xs={6} lg={10}>
                    <input className={'form-control'}
                        name="CompanyNameU"
                        onChange={this.onChangeInput}
                        value={inputData.CompanyNameU || ""}
                        disabled = {disabled}
                    />
                </Col>
            </FormGroup>
            <FormGroup>
                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Nguon_goc")}</label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                    <select className={'form-control'}
                        name="CaseSourceID"
                        value={inputData.CaseSourceID || ""}
                        onChange={this.onChangeInput}
                        disabled = {disabled}

                    >
                        <option value={''} />
                        {cboCaseSource && cboCaseSource.length > 0 && cboCaseSource.map((value) => {
                            return (<option key={value.CaseSourceID}
                                value={value.CaseSourceID}>{value.CaseSourceName}</option>)
                        })}
                    </select>
                </Col>

                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Nhom_kinh_doanh")}</label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                <DropDownBox
                    showClearButton
                    ref={(ref) => this.GroupSalesRef = ref}
                    width={'100%'}
                    className="input-devextreme"
                    value={inputData.GroupSalesID}
                    valueExpr={"GroupSalesID"}
                    displayExpr={"GroupSalesNameU"}
                    disabled={disabled}
                    dataSource={cboGroupSales}
                    onValueChanged ={this.onChangeGroupSalesDD}
                    contentRender={() => (<TreeList
                        width={"100%"}
                        height={300}
                        dataSource={cboGroupSales}
                        disabled={disabled}
                        showBorders={true}
                        columnAutoWidth={true}
                        wordWrapEnabled={true}
                        keyExpr={'GroupSalesID'}
                        parentIdExpr={'GroupSalesParentID'}
                        autoExpandAll={true}
                        showColumnHeaders={false}
                        id={'GroupSalesID'}
                        onSelectionChanged={this.onChangeGroupSales}
                                    >

                                        <Selection mode={'single'}

                                        />
                                        <Column  dataField={'GroupSalesNameU'}/>
                                    </TreeList>)}
                />


                </Col>


            </FormGroup>
            <FormGroup>
                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Nhom_nganh_nghe")}</label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                    <select className={'form-control'}
                        name="IndustryGroupID"
                        value={inputData.IndustryGroupID || ""}
                        onChange={this.onChangeInput}
                        disabled = {disabled}

                    >
                        <option value={''} />
                        {cboIndustryGroup && cboIndustryGroup.length > 0 && cboIndustryGroup.map((value) => {
                            return (<option key={value.IndustryGroupID}
                                value={value.IndustryGroupID}>{value.IndustryGroupName}</option>)
                        })}
                    </select>
                </Col>

                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Nhan_vien_kinh_doanh")}</label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                <Combo
                    //reset={this.state.reset}
                    showHeader={false}
                    dataSource={cboSalesPerson}
                    value={cboSalesPersonObj ? inputData.SalesPersonID : inputData.SalesPersonName}
                    valueExpr="SalesPersonID"
                    displayExpr="SalesPersonNameU"
                    disabled={disabled || isReadOnlyCustomerSetting}
                    onActionWhenSelectChange={(data) => {
                        this.props.onChangeInput && data && this.props.onChangeInput("SalesPersonID",data.SalesPersonID)
                    }}
                >
                    <Column dataField={'SalesPersonID'}
                            caption=""
                            minWidth={'150px'}
                            visible={false}
                            dataType={'string'}/>
                    <Column dataField={'UserID'}
                            caption=""
                            minWidth={'150px'}
                            visible={false}
                            dataType={'string'}/>
                    <Column cellRender={(e) => {
                        if (!e) return null;
                        const imageData = e.row.data.Image;

                        return (
                            <Row className={'mg0'}>
                                <Col sm={12} lg={12} className={'pd0'}>
                                    <div className='display_row staff-dropdown align-center'>
                                        <img className="image-sale-person"
                                             alt={""}
                                             src={imageData ? imageData : require('../../../images/icon-user-default.png')}
                                        />
                                        <div className='display_col staff-info'>
                                            <span>{e.row.data.SalesPersonNameU}</span>
                                            <span>{e.row.data.SalesPersonID}</span>
                                            <span>{e.row.data.JobPosition}</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        );
                    }}
                            dataField={'SalesPersonNameU'}
                            caption=""
                            minWidth={'150px'}
                            dataType={'string'}/>
                </Combo>
                </Col>


            </FormGroup>
            <FormGroup>
                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Phan_loai_KH")}</label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                    <select className={'form-control'}
                        name="CustomerLevelID"
                        value={inputData.CustomerLevelID || ""}
                        onChange={this.onChangeInput}
                        disabled = {disabled}

                    >
                        <option value={''} />
                        {cboCustomerLevel && cboCustomerLevel.length > 0 && cboCustomerLevel.map((value) => {
                            return (<option key={value.CustomerLevelID}
                                value={value.CustomerLevelID}>{value.CustomerLevelName}</option>)
                        })}
                    </select>
                </Col>




            </FormGroup>
            <FormGroup>
                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Mo_ta")}</label></Col>
                <Col  md={10} sm={10} xs={6} lg={10}>
                    <textarea className={'form-control'}
                        name="NotesU"
                        onChange={this.onChangeInput}
                        value = {inputData.NotesU || ""}
                        disabled = {disabled}

                    />
                </Col>
            </FormGroup>
        </div>
    </Collapse>

    }
    renderColapse2 =()=>{
        const {
            stateData,
            inputData={},
        } = this.props;
        const {mode,  } = stateData || {}
        const IsContactAdmin =inputData.IsContactAdmin === 1
        const disabled =  mode === "view" || IsContactAdmin;
        const isReadOnlyCustomerSetting = this.readOnlyCustomerSetting();

        return  <Collapse in={this.state.collapse1}>
        <div>
            <FormGroup>
                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang('CRM_Ho_ten')}{!IsContactAdmin&&<span
                        className={'text-red'}> (*)</span>}</label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                    <input className={'form-control'}
                        name="FullName"
                        onChange={this.onChangeInput}
                        value={inputData.FullName || ""}
                        disabled = {disabled || isReadOnlyCustomerSetting}
                    />
                </Col>

                <Col md={2} sm={2} xs={6} lg={2}/>
                <Col md={4} sm={4} xs={6} lg={4} className={'mgb15'}>
                    <div className = "display_row align-around">
                    <label className="radio-inline">
                        <input

                            type="radio"
                            name="Gender_NLH"
                            value="M"
                            checked={inputData.Gender_NLH !== "F"}
                            onChange={this.onChangeInput}
                            disabled = {disabled}
                        />
                        {Config.lang("CRM_Nam1")}
                    </label>
                    <label className="radio-inline">
                        <input
                            type="radio"
                            name="Gender_NLH"
                            value="F"
                            checked={inputData.Gender_NLH=== "F"}
                            onChange={this.onChangeInput}
                            disabled = {disabled}
                        />
                        {Config.lang("CRM_Nu")}
                    </label>
                    </div>

                </Col>


            </FormGroup>
            <FormGroup>
                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_So_dien_thoai")} {!IsContactAdmin&&<span
                        className={'text-red'}> (*)</span>}</label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                    <input className={'form-control'}
                        name="MobileNo_NLH"
                        onChange={this.onChangeInput}
                        value={inputData.MobileNo_NLH || ""}
                        disabled = {disabled || isReadOnlyCustomerSetting}
                    />
                </Col>

                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Email")} {!IsContactAdmin&&<span
                        className={'text-red'}> (*)</span>}</label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                    <input className={'form-control'}
                        name="Email2_NLH"
                        onChange={this.onChangeInput}
                        value={inputData.Email2_NLH || ""}
                        disabled = {disabled || isReadOnlyCustomerSetting}
                    />
                </Col>
            </FormGroup>

        </div>
    </Collapse>

    }
    renderColapse3 =()=>{
        const {
            stateData,
            inputData={},
            onChangeMasterInputs

        } = this.props;
        const {mode } = stateData || {}
        const disabled =  mode === "view"
        return  <Collapse in={this.state.collapse3}>
        <div>
            <FormGroup>
                <Col md={2} sm={2} xs={6} lg={2}><label
                                className="control-label">{Config.lang("CRM_Ngay_sinh")}</label>
                </Col>
                <Col md={4} sm={4} xs={6} lg={4} className={'mgb15'}>
                        <DateBox
                            name = "DateOfBirth"
                            width={"100%"}
                            dateSerializationFormat={'yyyy-MM-ddTHH:mm:ss'}
                            placeholder={Config.lang("CRM_Ngay_sinh")}
                            openOnFieldClick={true}
                            useMaskBehavior={true}
                            showClearButton={true}
                            type={'date'}
                            pickerType={"calendar"}
                            showAnalogClock={false}
                            displayFormat={'dd/MM/y'}
                            value={inputData.DateOfBirth}
                            onValueChanged={e => this.onChangeDateInput(e,"DateOfBirth")}
                            disabled = {disabled}
                        />
                </Col>

                <Col md={2} sm={2} xs={6} lg={2}/>
                <Col md={4} sm={4} xs={6} lg={4} className={'mgb15'}>
                    <div className = "display_row align-around">
                    <label className="radio-inline">
                        <input

                            type="radio"
                            name="Gender"
                            value="M"
                            checked={inputData.Gender !== "F"}
                            onChange={this.onChangeInput}
                            disabled = {disabled}
                        />
                        {Config.lang("CRM_Nam1")}
                    </label>
                    <label className="radio-inline">
                        <input
                            type="radio"
                            name="Gender"
                            value="F"
                            checked={inputData.Gender=== "F"}
                            onChange={this.onChangeInput}
                            disabled = {disabled}
                        />
                        {Config.lang("CRM_Nu")}
                    </label>
                    </div>

                </Col>
            </FormGroup>
            <FormGroup>
                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang('CRM_So_CCCD_/_CMND')}</label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                    <input className={'form-control'}
                        name="IDCardNo"
                        onChange={this.onChangeInput}
                        value={inputData.IDCardNo || ""}
                        disabled = {disabled}
                    />
                </Col>

                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Ngay_cap_CMND")}</label></Col>

                <Col md={4} sm={4} xs={6} lg={4} className={'mgb15'}>
                        <DateBox
                            name = "IDCardIssueDate"
                            width={"100%"}
                            dateSerializationFormat={'yyyy-MM-ddTHH:mm:ss'}
                            placeholder={Config.lang("CRM_Ngay_cap_CMND")}
                            openOnFieldClick={true}
                            useMaskBehavior={true}
                            showClearButton={true}

                            type={'date'}
                            pickerType={"calendar"}
                            showAnalogClock={false}
                            displayFormat={'dd/MM/y'}
                            value={inputData.IDCardIssueDate}
                            onValueChanged={e => this.onChangeDateInput(e,"IDCardIssueDate")}
                            disabled = {disabled}
                        />
                </Col>

            </FormGroup>
            <FormGroup>
                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Noi_cap_CMND")}</label></Col>
                <Col md={10} sm={10} xs={6} lg={10}>
                    <input className={'form-control'}
                        name="IDCardIssuePlaceU"
                        onChange={this.onChangeInput}
                        value={inputData.IDCardIssuePlaceU || ""}
                        disabled = {disabled}
                    />
                </Col>
            </FormGroup>
            <FormGroup>
                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang('CRM_Ho_chieu')}</label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                    <input className={'form-control'}
                        name="Passport"
                        onChange={this.onChangeInput}
                        value={inputData.Passport || ""}
                        disabled = {disabled}
                    />
                </Col>

                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Ngay_cap_ho_chieu")}</label></Col>

                <Col md={4} sm={4} xs={6} lg={4} className={'mgb15'}>
                        <DateBox
                            name = "PassportValidFrom"
                            width={"100%"}
                            dateSerializationFormat={'yyyy-MM-ddTHH:mm:ss'}
                            placeholder={Config.lang("CRM_Ngay_cap_ho_chieu")}
                            openOnFieldClick={true}
                            useMaskBehavior={true}
                            showClearButton={true}

                            type={'date'}
                            pickerType={"calendar"}
                            showAnalogClock={false}
                            displayFormat={'dd/MM/y'}
                            value={inputData.PassportValidFrom}
                            onValueChanged={e => this.onChangeDateInput(e,"PassportValidFrom")}
                            disabled = {disabled}
                        />

                </Col>
            </FormGroup>
            <FormGroup>
                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_So_dien_thoai")}<span
                    className={'text-red'}> (*)</span></label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                    <input className={'form-control'}
                        name="Telephone"
                        onChange={this.onChangeInput}
                        value={inputData.Telephone || ""}
                        disabled = {disabled}
                    />
                </Col>

                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Email")}</label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                    <input className={'form-control'}
                        name="Email"
                        onChange={this.onChangeInput}
                        value={inputData.Email || ""}
                        disabled = {disabled}
                    />
                </Col>
            </FormGroup>

           <W17F1026LocationInput
                AddressLabel = {Config.lang("CRM_Dia_chi_thuong_tru")}
                AddressValue = {inputData.AddressLineU}
                WardValue = {inputData.WardID}
                DistrictValue = {inputData.DistrictID}
                ProvinceValue = {inputData.ProvinceID}
                CountryValue = {inputData.CountryID}
                AddressKey = {`AddressLineU`}
                WardKey = {`WardID`}
                DistrictKey = {`DistrictID`}
                ProvinceKey = {`ProvinceID`}
                CountryKey = {`CountryID`}
                WardName = {`WardName`}
                DistrictName = {`DistrictNameU`}
                ProvinceName = {`ProvinceNameU`}
                CountryName = {`CountryNameU`}
                disabled = {disabled}
                onChange= {this.onChangeInput}
                onChangeMasterInputs ={onChangeMasterInputs}
            />

           <W17F1026LocationInput
                AddressLabel = {Config.lang("CRM_Dia_chi_lien_he")}
                AddressValue = {inputData.ContactAddressU}
                WardValue = {inputData.ContactWardID}
                DistrictValue = {inputData.ContactDistrictID}
                ProvinceValue = {inputData.ContactProvinceID}
                CountryValue = {inputData.ContactCountryID}
                AddressKey = {`ContactAddressU`}
                WardKey = {`ContactWardID`}
                DistrictKey = {`ContactDistrictID`}
                ProvinceKey = {`ContactProvinceID`}
                CountryKey = {`ContactCountryID`}
                WardName = {`ContactWardNameU`}
                DistrictName = {`ContactDistrictNameU`}
                ProvinceName = {`ContactProvinceNameU`}
                CountryName = {`ContactCountryNameU`}
                disabled = {disabled}
                onChange= {this.onChangeInput}
                onChangeMasterInputs ={onChangeMasterInputs}
            />
             </div>
    </Collapse>

    }
    renderColapse4 =()=>{
        const {
            stateData,
            inputData={},
            cboCompanyGroup=[],
            onChangeMasterInputs,
        } = this.props;
        const {mode,  } = stateData || {};
        const disabled =  mode === "view"
        return  <Collapse in={this.state.collapse4}>
        <div>


            <FormGroup>
                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Ma_so_thue")}</label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                    <input className={'form-control'}
                        name="TaxNo"
                        onChange={this.onChangeInput}
                        value={inputData.TaxNo || ""}
                        disabled = {disabled}
                    />
                </Col>

                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Giay_CN_DKKD")}</label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                    <input className={'form-control'}
                        name="LicenseNo"
                        onChange={this.onChangeInput}
                        value={inputData.LicenseNo || ""}
                        disabled = {disabled}
                    />
                </Col>
            </FormGroup>
            <FormGroup>
                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_So_dien_thoai")}<span
                    className={'text-red'}> (*)</span></label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                    <input className={'form-control'}
                        name="Telephone_Org"
                        onChange={this.onChangeInput}
                        value={inputData.Telephone_Org || ""}
                        disabled = {disabled}
                    />
                </Col>

                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_So_Fax")}</label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                    <input className={'form-control'}
                        name="CompanyFaxNo"
                        onChange={this.onChangeInput}
                        value={inputData.CompanyFaxNo || ""}
                        disabled = {disabled}
                    />
                </Col>
            </FormGroup>

            <FormGroup>


                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Email")}</label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                    <input className={'form-control'}
                        name="Email_Org"
                        onChange={this.onChangeInput}
                        value={inputData.Email_Org || ""}
                        disabled = {disabled}
                    />
                </Col>

                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Nhom_cong_ty")}</label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                    <select className={'form-control'}
                        name="CompanyGroupID"
                        value={inputData.CompanyGroupID || ""}
                        onChange={this.onChangeInput}
                        disabled = {disabled}

                    >
                        <option value={''} />
                        {cboCompanyGroup && cboCompanyGroup.length > 0 && cboCompanyGroup.map((value) => {
                            return (<option key={value.CompanyGroupID}
                                value={value.CompanyGroupID}>{value.CompanyGroupName}</option>)
                        })}
                    </select>
                </Col>

            </FormGroup>
           <W17F1026LocationInput
                AddressLabel = {Config.lang("CRM_Dia_chi_DKKD")}
                AddressValue = {inputData.RegisteredAddressU}
                WardValue = {inputData.RegisteredWardID}
                DistrictValue = {inputData.RegisteredDistrictID}
                ProvinceValue = {inputData.RegisteredProvinceID}
                CountryValue = {inputData.RegisteredCountryID}
                AddressKey = {`RegisteredAddressU`}
                WardKey = {`RegisteredWardID`}
                DistrictKey = {`RegisteredDistrictID`}
                ProvinceKey = {`RegisteredProvinceID`}
                CountryKey = {`RegisteredCountryID`}
                WardName = {`RegisteredWardNameU`}
                DistrictName = {`RegisteredDistrictNameU`}
                ProvinceName = {`RegisteredProvinceNameU`}
                CountryName = {`RegisteredCountryNameU`}
                disabled = {disabled}
                onChange= {this.onChangeInput}
                onChangeMasterInputs ={onChangeMasterInputs}
            />

           <W17F1026LocationInput
                AddressLabel = {Config.lang("CRM_Dia_chi_lien_he")}
                AddressValue = {inputData.CompanyContactAddressU}
                WardValue = {inputData.CompanyContactWardID}
                DistrictValue = {inputData.CompanyContactDistrictID}
                ProvinceValue = {inputData.CompanyContactProvinceID}
                CountryValue = {inputData.CompanyContactCountryID}
                AddressKey = {`CompanyContactAddressU`}
                WardKey = {`CompanyContactWardID`}
                DistrictKey = {`CompanyContactDistrictID`}
                ProvinceKey = {`CompanyContactProvinceID`}
                CountryKey = {`CompanyContactCountryID`}
                WardName = {`CompanyContactWardNameU`}
                DistrictName = {`CompanyContactDistrictNameU`}
                ProvinceName = {`CompanyContactProvinceNameU`}
                CountryName = {`CompanyContactCountryNameU`}
                disabled = {disabled}
                onChange= {this.onChangeInput}
                onChangeMasterInputs ={onChangeMasterInputs}
            />
             </div>
    </Collapse>

    }
    renderColapse5 =()=>{
        const {
            stateData,
            inputData={},

        } = this.props;
        const isReadOnlyCustomerSetting = this.readOnlyCustomerSetting();
        const {mode,  } = stateData || {}
        const disabled =  mode === "view"
        return  <Collapse in={this.state.collapse4}>
        <div>
              <FormGroup>
                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Ho_ten")}</label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                    <input className={'form-control'}
                        name="LegalPersonU"
                        onChange={this.onChangeInput}
                        value={inputData.LegalPersonU || ""}
                        disabled = {disabled || isReadOnlyCustomerSetting}

                    />
                </Col>

                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Chuc_vu")}</label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                    <input className={'form-control'}
                        name="LTitleU"
                        onChange={this.onChangeInput}
                        value={inputData.LTitleU || ""}
                        disabled = {disabled || isReadOnlyCustomerSetting}
                    />
                </Col>
            </FormGroup>

              <FormGroup>
                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Ngay_sinh")}</label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                <DateBox
                            name = "LBirthday"
                            width={"100%"}
                            dateSerializationFormat={'yyyy-MM-ddTHH:mm:ss'}
                            placeholder={Config.lang("CRM_Ngay_sinh")}
                            openOnFieldClick={true}
                            useMaskBehavior={true}
                            showClearButton={true}

                            type={'date'}
                            pickerType={"calendar"}
                            showAnalogClock={false}
                            displayFormat={'dd/MM/y'}
                            value={inputData.LBirthday}
                            onValueChanged={e => this.onChangeDateInput(e,"LBirthday")}
                            disabled = {disabled || isReadOnlyCustomerSetting}
                        />
                </Col>

                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang('CRM_So_CCCD_/_CMND')}</label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                    <input className={'form-control'}
                        name="LIDCardNo"
                        onChange={this.onChangeInput}
                        value={inputData.LIDCardNo || ""}
                        disabled = {disabled || isReadOnlyCustomerSetting}
                    />
                </Col>
            </FormGroup>

              <FormGroup>
                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Ngay_cap_CMND")}</label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                <DateBox
                            name = "LIDCardIssueDate"
                            width={"100%"}
                            dateSerializationFormat={'yyyy-MM-ddTHH:mm:ss'}
                            placeholder={Config.lang("CRM_Ngay_cap_CMND")}
                            openOnFieldClick={true}
                            useMaskBehavior={true}
                            showClearButton={true}

                            type={'date'}
                            pickerType={"calendar"}
                            showAnalogClock={false}
                            displayFormat={'dd/MM/y'}
                            value={inputData.LIDCardIssueDate}
                            onValueChanged={e => this.onChangeDateInput(e,"LIDCardIssueDate")}
                            disabled = {disabled || isReadOnlyCustomerSetting}
                        />
                </Col>

                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Noi_cap_CMND")}</label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                    <input className={'form-control'}
                        name="LIDCardIssuePlaceU"
                        onChange={this.onChangeInput}
                        value={inputData.LIDCardIssuePlaceU || ""}
                        disabled = {disabled || isReadOnlyCustomerSetting}
                    />
                </Col>
            </FormGroup>

              <FormGroup>
                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_So_dien_thoai")}</label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                    <input className={'form-control'}
                        name="LMobileNo"
                        onChange={this.onChangeInput}
                        value={inputData.LMobileNo || ""}
                        disabled = {disabled || isReadOnlyCustomerSetting}
                    />
                </Col>

                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Email")}</label></Col>
                <Col  md={4} sm={4} xs={6} lg={4}>
                    <input className={'form-control'}
                        name="LEmail"
                        onChange={this.onChangeInput}
                        value={inputData.LEmail || ""}
                        disabled = {disabled || isReadOnlyCustomerSetting}
                    />
                </Col>
            </FormGroup>
            <FormGroup>
                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Dia_chi_thuong_tru")}</label></Col>
                <Col md={10} sm={10} xs={6} lg={10}>
                    <input className={'form-control'}
                        name="LAddressLineU"
                        onChange={this.onChangeInput}
                        value={inputData.LAddressLineU || ""}
                        disabled = {disabled || isReadOnlyCustomerSetting}
                    />
                </Col>
            </FormGroup>
            <FormGroup>
                <Col md={2} sm={2} xs={6} lg={2}><label
                    className={"control-label"}>{Config.lang("CRM_Dia_chi_lien_he")}</label></Col>
                <Col md={10} sm={10} xs={6} lg={10}>
                    <input className={'form-control'}
                        name="LContactAddressU"
                        onChange={this.onChangeInput}
                        value={inputData.LContactAddressU   || ""}
                        disabled = {disabled || isReadOnlyCustomerSetting}
                    />
                </Col>
            </FormGroup>
        </div>
    </Collapse>

    }

    renderColapse6 = () => {
        const { collapse6, dataProgressStatus } = this.state;
        const { Status = [], StatusValue = 0 } = dataProgressStatus;
        return <Collapse in={collapse6}>
            <div>
                <DynamicStepComponent
                    step={StatusValue}
                    statusList={Status} />
            </div>
        </Collapse>
    };

    readOnlyCustomerSetting = () => {
        const { stateData, customerSettingValue } = this.props;
        return stateData?.mode === 'edit' && customerSettingValue?.Value === 1;
    };

    render() {


        const {
            iPermission,
            iPermissionConvert,
            stateData = {},
            inputData,
            comeBack,
            //func
            onSave,
            onEdit,
            isSaved,
            onAddMore,
            btnConvertAction,
            disableConvertBtn
        } = this.props;
        const {mode, permission:iPermissionState  } = stateData || {}
        const IsPerson = inputData.IsPerson === 1
        const AddMode = mode === "add"
        const EditMode = mode === "edit"
        const ViewMode = mode === "view"
        const { dataProgressStatus } = this.state;
                return (
            <>
            <Form horizontal={true} id={'frmW17F1026'} style={{margin: '0 15px'}}>
                    <Row className="form-group">
                        <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                            <ButtonCustom color={'text-orange'} icon={'fas fa-arrow-alt-left'} isAwesome={true}
                                          name={Config.lang("CRM_Quay_lai")}
                                          onClick={() => {
                                            comeBack&&comeBack()
                                          }}/>
                            <ButtonEdit
                                className={`mgl5 ${iPermissionState >=3 ? "" : "isDisabled"} ${ViewMode? "" : "hide"}`}
                                name={Config.lang("CRM_Sua")} onClick={() => {
                                    if (iPermissionState >=3) {
                                        onEdit&&onEdit()
                                    }
                                }}
                            />
                            <ButtonSave className={`mgl5 ${iPermission >=2 ? "" : "isDisabled"} ${(AddMode || EditMode)? "" : "hide"}`}
                                name={Config.lang("CRM_Luu")} onClick={() => {
                                    if (iPermission >=2) {
                                        onSave&&onSave()
                                    }
                                }}
                            />
                            <ButtonAdd className={`mgl5 ${iPermission >=2 ? "" : "isDisabled"} ${(isSaved)? "" : "hide"}`}
                                name={Config.lang("CRM_Nhap_tiep")} onClick={() => {
                                    if (iPermission >=2) {
                                        onAddMore&&onAddMore()
                                    }
                                }}
                            />
                            {iPermissionConvert >= 2 && <ButtonConvert
                                disabled={disableConvertBtn || inputData.Approved === 1}
                                className={disableConvertBtn || inputData.Approved === 1 ? 'isDisabled mgl5' : 'mgl5'}
                                name={Config.lang("CRM_Yeu_cau_chuyen_doi")} onClick={() => btnConvertAction()}/>}

                        </Col>

                    </Row>

                </Form>

            <Form horizontal={true} id={'frmW17F1026'} style={{margin: '0 15px'}}>
                {/* collapse1 */}
                <FormGroup>
                    <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                        <span onClick={() => this.toggle(1)}><i
                            className={this.state.collapse1 ? 'fas fa-caret-down fa-lg ' : 'fas fa-caret-right fa-lg '} /> <label
                            style = {{cursor:"pointer"}}    className={'text-blue'}>&nbsp;{Config.lang("CRM_Thong_tin_chung")}</label></span>
                    </Col>
                </FormGroup>
                {this.renderColapse1()}
                {/* collapse2 */}
                {
                    this.state.collapse1 && <FormGroup className ="display_row align-center">
                    <Col md={2} sm={2} xs={6} lg={2}>
                        <span style = {{cursor:"pointer"}} >
                            <label
                                style = {{cursor:"pointer"}}
                                className={'text-blue'}
                            >
                                &nbsp;{Config.lang("CRM_Nguoi_lien_he_chinh")}


                            </label>
                        </span>
                    </Col>
                    <Col md={10} sm={10} xs={6} lg={10}>
                         <FormControlLabel
                            control={<Checkbox

                                color="primary"
                                name ="IsContactAdmin"
                                value="1"
                                onChange= {this.onChangeInput}
                                checked={inputData.IsContactAdmin === 1}
                                disabled ={mode ==="view"}
                            />}
                            label={Config.lang("CRM_Khach_hang_la_nguoi_lien_he")}
                        />
                    </Col>
                </FormGroup>
                }
                {this.renderColapse2()}
                {/* collapse3 */}
                <FormGroup>
                    <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                        <span style = {{cursor:"pointer"}} onClick={() =>IsPerson && this.toggle(3)}><i
                            className={this.state.collapse3 ? 'fas fa-caret-down fa-lg ' : 'fas fa-caret-right fa-lg '} /> <label
                            style = {{cursor:"pointer"}}    className={'text-blue'}>&nbsp;{Config.lang("CRM_Thong_tin_ca_nhan")}</label></span>
                    </Col>
                </FormGroup>
                {IsPerson && this.renderColapse3()}
                <FormGroup>
                    <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                        <span style = {{cursor:"pointer"}} onClick={() => !IsPerson && this.toggle(4)}><i
                            className={this.state.collapse4? 'fas fa-caret-down fa-lg ' : 'fas fa-caret-right fa-lg '} /> <label
                            style = {{cursor:"pointer"}}    className={'text-blue'}>&nbsp;{Config.lang("CRM_Thong_tin_cong_ty")}</label></span>
                    </Col>
                </FormGroup>
                {!IsPerson && this.renderColapse4()}
               {
                  !IsPerson && this.state.collapse4 &&  <FormGroup>
                   <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                       <span style = {{cursor:"pointer"}} ><label
                           style = {{cursor:"pointer"}}   className={'text-blue'}>&nbsp;{Config.lang("CRM_Nguoi_dai_dien_phap_luat")}</label></span>
                   </Col>
                   <Col smHidden={true} md={10} xl={10} lg={10}>
                      <div className= "border"></div>
                   </Col>
               </FormGroup>
               }
                {!IsPerson && this.renderColapse5()}
               {ViewMode && <>
                        <FormGroup>
                            <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                                <span style={{ cursor: "pointer" }} onClick={() => this.toggle(6)}><i
                                    className={this.state.collapse6 ? 'fas fa-caret-down fa-lg ' : 'fas fa-caret-right fa-lg '} /> <label
                                        style={{ cursor: "pointer" }} className={'text-blue'}>&nbsp;{Config.lang("Trang_thai_chi_tiet")}</label></span>
                            </Col>
                        </FormGroup>
                        {Object.keys(dataProgressStatus).length > 0 && this.renderColapse6()}
                    </>}
                <FormGroup className={'mgt10'}>
                        <Col md={2} sm={2} xl={2} xs={12} lg={2}>
                                    <span style = {{cursor:"pointer"}} className={'text-blue text-bold'} onClick={() => {
                                        this.toggle(0)
                                    }}>{this.state.collapse0 ? Config.lang("CRM_Thu_gon") : Config.lang("CRM_Xem_them")}</span>
                                    </Col>

                    </FormGroup>
            </Form>
           </>

        )
    }
}
export default connect(state => {
    const {//cbo
        cboCompanyType,
        cboCaseSource,
        cboIndustryGroup,
        cboCompanyGroup,
        cboCustomerLevel,
        cboGroupSales,
        cboSalesPerson,
        cboCountry,
        customerSettingValue} = state.w17f1026

        return {
            cboCompanyType,
            cboCaseSource,
            cboIndustryGroup,
            cboCompanyGroup,
            cboCustomerLevel,
            cboGroupSales,
            cboSalesPerson,
            cboCountry,
            customerSettingValue
        }


},
(dispatch) => ({
    W17F1026Actions: bindActionCreators(W17F1026Actions, dispatch),
}))(W17F1026Master)


